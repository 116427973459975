<template>
	<div class="flex h-screen items-center justify-center">
		<div
			class="mx-4 overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:mx-0 sm:my-8"
		>
			<div class="bg-white p-5 sm:p-6">
				<div class="text-left sm:ml-4 sm:text-left">
					<h3
						class="text-xl font-semibold leading-6 text-slate-900"
						id="modal-title"
					>
						<span>
							{{ $s('Core.Label.CropImage') }}
						</span>
					</h3>

					<cropper
						v-if="fileBlob"
						ref="cropperRef"
						class="cropper"
						:stencil-component="CircleStencil"
						:resizeImage="{ wheel: false }"
						:src="fileBlob"
					/>
				</div>
			</div>
			<div
				class="flex flex-row-reverse items-end border-t bg-slate-50 px-4 py-3 sm:px-6"
			>
				<ButtonGroup>
					<Button theme="neutral" @click="cancel" size="small">
						{{ $s('Core.Button.Cancel') }}
					</Button>
					<Button theme="primary" @click="confirm" size="small">
						{{ $s('Core.Button.CropAndSave') }}
					</Button>
				</ButtonGroup>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

const props = defineProps<{
	file: File;
	resolve: (value: any, payload?: File) => void;
}>();

const fileBlob = ref<string | null>(URL.createObjectURL(props.file));
const imageType = ref<string | null>(props.file.type);
const cropperRef = ref();

function confirm() {
	const { canvas } = cropperRef.value.getResult();
	canvas.toBlob((blob: Blob) => {
		if (blob) {
			const file = new File([blob], 'cropped-image', {
				type: imageType.value!,
			});
			props.resolve(true, file);
		} else {
			console.error('Failed to create blob from canvas');
		}
	}, imageType.value);
}

function cancel() {
	fileBlob.value = null;
	props.resolve(false);
}
</script>
<style scoped>
@reference '~/assets/css/style.css';

.cropper {
	height: 100%;
	width: 100%;
	@apply mt-4 max-h-[70vh] max-w-[70vw] overflow-hidden rounded-xl;
}
</style>
