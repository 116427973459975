<template>
	<div class="flex h-screen items-center justify-center">
		<div
			class="mx-4 w-full max-w-96 overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:mx-0 sm:my-8 sm:max-w-lg"
		>
			<div class="bg-white p-5 sm:p-6">
				<div class="flex items-start">
					<div
						class="mx-auto hidden h-12 w-12 shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:flex sm:h-10 sm:w-10"
					>
						<Icon
							:name="ICON_WARNING"
							class="h-7 w-7 text-primary-900"
							aria-hidden="true"
						/>
					</div>
					<div class="text-left sm:ml-4 sm:text-left">
						<h3
							class="text-xl font-semibold leading-6 text-slate-900"
							id="modal-title"
						>
							{{ $s('Core.Header.ModalSave') }}
						</h3>
						<div class="mt-2">
							<p class="text-sm text-slate-500">
								{{ $s('Core.Info.ModalSave') }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div
				class="flex flex-row-reverse items-end border-t bg-slate-50 px-4 py-3 sm:px-6"
			>
				<ButtonGroup>
					<Button theme="neutral" @click="cancel" size="small">
						{{ $s('Core.Button.Cancel') }}
					</Button>
					<Button theme="primary" @click="confirm" size="small">
						{{ $s('Core.Button.Leave') }}
					</Button>
				</ButtonGroup>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
const props = defineProps<{
	resolve: (value: any) => void;
}>();

function confirm() {
	props.resolve(true);
}

function cancel() {
	props.resolve(false);
}
</script>
