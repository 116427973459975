export default function () {
	const config = useRuntimeConfig();
	const { impersonatingId } = useImpersonate();

	let restDefaults = {
		baseURL: config.public.BFF_URL,
		credentials: 'include' as const,
		headers: {},
	};

	if (impersonatingId.value) {
		restDefaults.headers = {
			'Impersonate-Id': impersonatingId.value,
		};
	}

	return { restDefaults };
}
