<template>
	<MenuItem v-slot="{ active }" as="template">
		<NuxtLink
			v-if="to"
			:to="to"
			:class="[
				menuItemClasses(active),
				!actionType &&
					'text-slate-600 hover:bg-slate-50 hover:text-slate-900',
				actionType === 'success' &&
					'text-green-600 hover:bg-green-50 hover:text-green-700',
				actionType === 'delete' &&
					'text-red-600 hover:bg-red-50 hover:text-red-700',
			]"
		>
			<Icon
				v-if="icon"
				:name="icon"
				:class="iconClasses"
				aria-hidden="true"
			/>
			<slot></slot>
		</NuxtLink>

		<button
			v-else
			@click="emit('click')"
			:class="[
				menuItemClasses(active),
				!actionType &&
					'text-slate-600 hover:bg-slate-50 hover:text-slate-900',
				actionType === 'success' &&
					'text-green-600 hover:bg-green-50 hover:text-green-700',
				actionType === 'delete' &&
					'text-red-600 hover:bg-red-50 hover:text-red-700',
			]"
		>
			<Icon
				v-if="icon"
				:name="icon"
				:class="iconClasses"
				aria-hidden="true"
			/>
			<slot></slot>
		</button>
	</MenuItem>
</template>

<script setup lang="ts">
import { MenuItem } from '@headlessui/vue';
const props = withDefaults(
	defineProps<{
		icon?: string;
		size?: 'xsmall' | 'default';
		to?: string | object;
		link?: boolean;
		actionType?: 'success' | 'delete';
	}>(),
	{
		size: 'default',
	}
);

const emit = defineEmits<{
	(e: 'click'): void;
}>();

function menuItemClasses(active: boolean): string {
	const base =
		'group flex w-full items-center whitespace-nowrap rounded-sm transition-colors border font-medium';
	const activeState = active
		? 'border-slate-200 bg-slate-100 text-slate-800'
		: 'border-transparent';
	const sizeClasses = {
		xsmall: 'px-2 py-0.5 text-xs gap-x-1.5',
		default: props.icon
			? 'pl-1.5 pr-2 py-1 text-xs sm:pl-2 sm:pr-3 sm:py-1.5 sm:text-sm gap-x-2'
			: 'px-2 py-1 text-xs sm:px-3 sm:py-1.5 sm:text-sm gap-x-2',
	};

	return `${base} ${activeState} ${sizeClasses[props.size || 'default']}`;
}

const iconClasses = computed(() => {
	const baseIconClasses = 'shrink-0 text-inherit opacity-75';
	return props.size === 'xsmall'
		? `${baseIconClasses} size-4`
		: `${baseIconClasses} size-5`;
});
</script>
