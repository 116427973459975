import { changeLocale } from '@formkit/vue';
import { enUS, nb } from 'date-fns/locale';

export default function () {
	const currentLanguageId = useState<number | null>(
		'currentLanguageId',
		() => {
			const storedClientId = localStorage.getItem('currentLanguageId');
			if (storedClientId) {
				return parseInt(storedClientId);
			}

			// Norwegian as fallback
			return 1;
		}
	);

	const currentLocale = computed(() => {
		return currentLanguageId.value === 1 ? 'nb' : 'en';
	});

	const currentDateFnsLocale = computed(() => {
		return currentLanguageId.value === 1 ? nb : enUS;
	});

	function setLanguageId(languageId: number) {
		currentLanguageId.value = languageId;
		localStorage.setItem('currentLanguageId', languageId.toString());

		changeLocale(languageId === 1 ? 'nb' : 'en');
	}

	return {
		currentLanguageId,
		setLanguageId,
		currentLocale,
		currentDateFnsLocale,
	};
}
