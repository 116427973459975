<template>
	<div class="rounded-full bg-slate-200">
		<NuxtLink :to="`/organisations/${profile?.organisationId}`">
			<Image
				v-if="profile?.organisationSymbol"
				:src="profile.organisationSymbol"
				:height="72"
				:width="72"
				class="aspect-square h-9 w-9 rounded-full bg-slate-900 object-cover transition-opacity hover:opacity-90"
				:alt="profile?.organisationName"
			/>
			<div v-else>
				<div
					class="flex h-9 w-9 items-center justify-center rounded-full bg-white text-xl font-bold text-primary"
				>
					{{ profile?.organisationName[0] }}
				</div>
			</div>
		</NuxtLink>
	</div>
</template>

<script setup lang="ts">
const { profile } = useProfile();
</script>
