<template>
	<time :datetime="date" :title="formatDateTime(date)">
		{{ localizedTimeAgo }}
	</time>
</template>

<script setup lang="ts">
import { fromZonedTime } from 'date-fns-tz';

const props = withDefaults(
	defineProps<{
		date?: Date | number | string;
		disableUtcForGraphql?: boolean;
	}>(),
	{
		disableUtcForGraphql: false,
	},
);

const { localizedTimeAgo } = useLocalizedTimeAgo(props.date, {
	_disableUtcForGraphql: props.disableUtcForGraphql,
});

function formatDateTime(timestamp: string): string {
	return new Date(fromZonedTime(timestamp, 'UTC')).toLocaleString('nb-NO', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});
}
</script>
