<template>
	<header
		class="fixed inset-x-0 top-0 z-50 h-[var(--header-height)] overflow-y-visible bg-[var(--app-header-bg)] lg:h-[var(--header-height-lg)]"
		:class="{
			'2xl:right-(--quicknote-width)!': isQuickNoteOpen,
			'bg-slate-800': profile?.isImpersonated,
		}"
	>
		<div class="mx-auto h-14">
			<div class="relative flex h-full">
				<!-- Sidebar toggle-->
				<div
					class="z-60 flex h-[var(--header-height)] w-16 items-center justify-center lg:hidden"
				>
					<button
						type="button"
						class="flex w-full items-center justify-center p-4 text-white lg:hidden"
						@click="toggleSidebar"
					>
						<span class="sr-only"
							>{{ sidebarOpen ? 'Close' : 'Open' }} sidebar</span
						>

						<Icon
							:name="sidebarOpen ? ICON_X : ICON_BARS"
							class="h-7 w-7"
							aria-hidden="true"
						/>
					</button>
				</div>

				<div class="mt-1 inline-flex lg:ml-3">
					<AppLogo type-of-logo="white" />
				</div>

				<!-- Search -->
				<AppSearch />

				<!-- Actions -->
				<div class="ml-auto flex items-center justify-end gap-x-3 px-4">
					<!-- Notification inbox -->
					<NotificationInbox />

					<!-- Quick note -->
					<button
						type="button"
						class="relative group flex size-9 items-center justify-center rounded-full bg-white/20 ring-1 ring-white/10 ring-inset"
						@click="toggleQuickNote"
					>
						<Icon
							:name="
								isQuickNoteOpen
									? 'ph:pencil-slash-duotone'
									: ICON_EDIT
							"
							class="size-5 text-white transition-transform group-hover:scale-110 group-hover:transform"
						/>
					</button>

					<span class="h-6 w-px bg-white/20" />

					<!-- Organisation dropdown -->
					<OrganisationSwitch />

					<!-- Profile dropdown -->
					<Menu as="div" class="relative shrink-0">
						<MenuButton
							class="group flex overflow-hidden rounded-full bg-slate-200"
						>
							<span class="sr-only">{{
								$s('Core.Button.OpenUserMenu')
							}}</span>

							<Image
								v-if="profile?.profilePicture"
								:src="profile.profilePicture"
								:height="72"
								:width="72"
								class="aspect-square h-9 w-9 rounded-full object-cover transition-opacity group-hover:opacity-90"
								:alt="`${profile?.firstName} ${profile?.lastName} `"
							/>
							<div
								v-else
								class="flex h-9 w-9 items-center justify-center rounded-full bg-white font-bold text-primary"
							>
								{{ profile?.initials }}
							</div>
						</MenuButton>

						<transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<MenuItems
								class="absolute right-0 z-10 mt-2 min-w-fit origin-top-right rounded-md bg-white p-1 py-1 shadow-lg ring-1 ring-slate-200 focus:outline-hidden"
							>
								<ButtonMenuItem
									@click="
										navigateTo(`/employees/${profile?.id}`)
									"
									class="font-semibold"
								>
									{{ profile?.firstName }}
									{{ profile?.lastName }}
								</ButtonMenuItem>

								<ButtonMenuSeparator />

								<ButtonMenuItem
									v-if="profile?.isImpersonated"
									@click="stopImpersonating"
									:icon="ICON_IMPERSONATE"
								>
									Bytt tilbake til
									{{ profile.impersonatedBy?.firstName }}
									{{ profile.impersonatedBy?.lastName }}
								</ButtonMenuItem>

								<ButtonMenuItem
									v-if="
										currentClient &&
										clients &&
										clients.length > 1
									"
									@click="navigateTo('/auth/client')"
									icon="ph:hard-drives-duotone"
								>
									{{ $s('Core.Button.SwitchClient') }}
								</ButtonMenuItem>

								<ButtonMenuItem
									@click="logout"
									:icon="ICON_LOGOUT"
								>
									{{ $s('Core.Button.Logout') }}
								</ButtonMenuItem>

								<template v-if="isDeveloper">
									<ButtonMenuSeparator />

									<ButtonMenuItem
										@click="clearAccess"
										:icon="ICON_X_CIRCLE"
									>
										{{ $s('Core.Button.ClearAccessCache') }}
									</ButtonMenuItem>
								</template>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';

const { isDeveloper } = useAccessControl();

const { isQuickNoteOpen, toggleQuickNote } = useQuickNoteUtils();

const { profile } = useProfile();
const { logout } = useAuthAxos();

const { stopImpersonating } = useImpersonate();

const { currentClient, clients } = useAuthClient();

const { sidebarOpen, toggleSidebar } = useSidebar();

const { clearAccessCache } = useClearAccessCache();
async function clearAccess() {
	await clearAccessCache({ invalidateQueries: true });
	$success($s('Core.Info.CacheEmptied'));
}
</script>
