<template>
	<div ref="containerRef">
		<!-- Selection button -->
		<div class="relative flex" :class="{ 'cursor-not-allowed': disabled }">
			<button
				ref="mainButtonRef"
				type="button"
				:id="id"
				class="w-full min-w-0 items-center rounded-md border bg-white px-2.5 py-1 text-left text-xs group-data-invalid:border-red-700 group-data-invalid:ring-1 group-data-invalid:ring-red-700 focus:ring-2 focus:ring-primary-500 focus:outline-hidden focus-visible:z-10 sm:py-1.5 sm:text-sm"
				:class="[
					isError ? 'border-red-300' : 'border-slate-200',
					disabled || isLoading ? 'cursor-not-allowed' : '',
					disabled ? 'bg-slate-75! text-slate-400!' : '',
					isOpen ? 'ring-1 ring-slate-300' : '',
				]"
				@click="!isLoading && !disabled && !isOpen && togglePopover()"
				@keydown.space.prevent="
					!isLoading && !disabled && togglePopover()
				"
				@keydown.enter.prevent="
					!isLoading && !disabled && togglePopover()
				"
				@focus="!displayValue && openPopover()"
				:aria-expanded="isOpen"
				:aria-controls="popoverId"
				:aria-invalid="isError"
				:disabled="disabled || isLoading"
				role="combobox"
				tabindex="0"
			>
				<div
					class="pointer-events-none flex items-center justify-between gap-x-1"
				>
					<span class="block max-w-[calc(100%-20px)] truncate">
						<span
							v-if="placeholder && !isOpen && !displayValue"
							class="text-slate-400"
						>
							{{ placeholder }}
						</span>

						<span
							v-else
							v-html="displayValue"
							class="flex items-center gap-x-1.5"
						></span>
					</span>
					<Icon
						:name="ICON_CHEVRON_UP_DOWN"
						class="formkit-inline-select-chevron h-5 w-5 shrink-0 text-slate-400"
						aria-hidden="true"
					/>
				</div>
			</button>
		</div>

		<!-- Error message -->
		<p
			v-if="isError && errorMessage"
			class="mt-1 text-xs text-red-500 sm:text-sm"
		>
			{{ errorMessage }}
		</p>

		<div
			ref="popoverRef"
			:id="popoverId"
			class="ax-popovertarget absolute z-10 min-w-56 rounded-lg border border-slate-200 bg-white p-1.5 shadow-lg"
			role="dialog"
			aria-modal="true"
			popover="manual"
		>
			<!-- Search field -->
			<div class="relative mb-2">
				<div
					class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
				>
					<Icon
						:name="ICON_SEARCH"
						class="h-4 w-4 text-slate-400"
						aria-hidden="true"
					/>
				</div>

				<input
					ref="searchInputRef"
					v-model="searchQuery"
					type="search"
					class="block w-full rounded-md border-0 py-1.5 pr-2.5 pl-9 text-xs text-slate-900 ring-1 ring-slate-300 ring-inset placeholder:text-slate-400 focus:ring-2 focus:ring-primary-500 focus:ring-inset sm:text-sm sm:leading-6"
					:placeholder="$s('Core.Label.Search')"
					role="searchbox"
					:aria-controls="`${popoverId}-listbox`"
					:aria-activedescendant="highlightedOptionId"
					@keydown.down.prevent="navigateOptions('next')"
					@keydown.up.prevent="navigateOptions('prev')"
					@keydown.enter.prevent="selectHighlightedOption"
				/>
			</div>

			<!-- Options list -->
			<div
				v-if="isOpen"
				class="relative max-h-64 ax-scrollbar-thin overflow-y-auto overscroll-contain text-xs sm:text-sm"
				:id="`${popoverId}-listbox`"
				role="listbox"
				@keydown.down.prevent="navigateOptions('next')"
				@keydown.up.prevent="navigateOptions('prev')"
				@keydown.enter.prevent="selectHighlightedOption"
			>
				<div
					v-if="filteredOptions.length > 0"
					class="flex flex-col gap-px"
					:class="{ 'pb-1': showCreate }"
				>
					<button
						v-for="(option, index) in filteredOptions"
						:key="option.value"
						type="button"
						ref="optionRefs"
						:id="`${popoverId}-option-${option.value}`"
						class="relative flex w-full items-center gap-3 rounded-md border px-2.5 py-0.5 text-left outline-hidden transition-colors sm:py-1"
						:class="[
							isSelected(option.value)
								? 'focus-visible::bg-primary-200 border-primary-300! bg-primary-100 text-slate-900 hover:bg-primary-200 focus:border-primary-500'
								: '',
							index === highlightedIndex &&
							!isSelected(option.value)
								? 'bg-slate-100 text-slate-900'
								: 'border-transparent',
							index === highlightedIndex &&
							isSelected(option.value)
								? 'bg-primary-200'
								: '',
						]"
						@click="selectOption(option)"
						@mousemove="highlightedIndex = index"
						@mouseleave="highlightedIndex = -1"
						@keydown.enter.prevent="selectOption(option)"
						@keydown.space.prevent="selectOption(option)"
						@keydown.tab="handleTabNavigation"
						role="option"
						:aria-selected="isSelected(option.value)"
						tabindex="0"
					>
						<template v-if="image">
							<Image
								v-if="image(option.attrs)"
								:src="image(option.attrs)"
								:alt="`Avatar for ${option.label}`"
								:height="24"
								:width="24"
								class="size-5 shrink-0 rounded-full object-cover sm:size-6"
							/>

							<div
								v-else
								:class="[
									'flex size-5 items-center justify-center rounded-full text-[10px] font-semibold sm:size-6',
									getTextColorClass(option.label),
								]"
							>
								{{ getInitials(option.label) }}
							</div>
						</template>
						<div class="min-w-0 flex-1">
							<div class="flex flex-col">
								<span
									class="flex items-center gap-x-1 truncate leading-5 font-medium"
									v-html="
										optionFormatter
											? optionFormatter(option)
											: option.label
									"
								></span>
								<span
									v-if="
										description && description(option.attrs)
									"
									class="truncate text-xs leading-3 text-slate-500"
									v-html="description(option.attrs)"
								>
								</span>
							</div>
						</div>
						<Icon
							v-if="isSelected(option.value)"
							:name="ICON_CHECK"
							class="ml-auto h-4 w-4 shrink-0 text-primary-500"
							aria-hidden="true"
						/>
					</button>
				</div>
				<div
					v-else
					class="px-3 pt-1 pb-2.5 text-xs text-slate-500 sm:text-sm"
				>
					{{ $s('Core.Info.NoResults') }}
				</div>

				<!-- Create new option -->
				<div
					v-if="showCreate"
					class="sticky bottom-0 w-full border-t border-slate-200 bg-white pt-1 pl-px"
				>
					<button
						ref="createButtonRef"
						type="button"
						class="flex w-full items-center space-x-1.5 rounded-md border border-transparent px-2 py-1.5 text-left text-xs text-green-500 transition-colors hover:border-green-200 hover:bg-green-50 hover:text-green-600 sm:text-sm"
						@click="handleCreate"
						@keydown.up.prevent="navigateOptions('prev')"
						tabindex="0"
					>
						<Icon
							:name="ICON_ADD"
							class="size-5 text-inherit"
							aria-hidden="true"
						/>
						<span v-if="searchQuery">
							{{ $s('Core.Label.CreateNew') }}: {{ searchQuery }}
						</span>
						<span v-else>{{ $s('Core.Label.CreateNew') }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts" generic="TOptionType">
import { DropdownItem, DropdownItemAttrs } from '~/types/dropdown';

// Types
export type AutocompleteOption = {
	value: string | number;
	label: string;
	image?: string;
	profilePicture?: string;
};

const props = withDefaults(
	defineProps<{
		id?: string;
		modelValue: string | number | (string | number)[];
		options: DropdownItem<TOptionType>[];
		showCreate?: boolean;
		name?: string;
		multiselect?: boolean;
		isLoading?: boolean;
		isError?: boolean;
		errorMessage?: string;
		disabled?: boolean;
		openOnMount?: boolean;
		placeholder?: string;
		scrollToHighlighted?: boolean;
		image?: (option: DropdownItemAttrs<TOptionType>) => string | undefined;
		description?: (
			option: DropdownItemAttrs<TOptionType>,
		) => string | undefined;
		displayFormatter?: (option: DropdownItem<TOptionType>) => string;
		optionFormatter?: (option: DropdownItem<TOptionType>) => string;
	}>(),
	{
		showCreate: false,
		name: 'autocomplete',
		multiselect: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		disabled: false,
		scrollToHighlighted: true,
	},
);

// Emits
const emit = defineEmits<{
	(
		e: 'update:modelValue',
		value: null | string | number | (string | number)[],
	): void;
	(e: 'create', value?: string): void;
	(e: 'search', value: string): void;
	(e: 'onPopoverToggle', visible: boolean): void;
}>();

// State
const isOpen = ref(false);
const containerRef = ref<HTMLElement>();
const popoverRef = ref<HTMLElement>();
const searchInputRef = ref<HTMLInputElement>();
const searchQuery = ref('');
const highlightedIndex = ref(-1);
const optionRefs = ref<HTMLButtonElement[]>([]);
const createButtonRef = ref<HTMLButtonElement>();
const mainButtonRef = ref<HTMLButtonElement>();

// Add new state to track if we're intentionally closing
const isIntentionalClose = ref(false);

// Add computed origin
const computedOrigin = computed(() => {
	const rect = containerRef.value?.getBoundingClientRect();
	if (!rect) return 'left';

	const screenWidth = window.innerWidth;
	const triggerRight = rect.right;
	const distanceToRight = screenWidth - triggerRight;
	const distanceToLeft = rect.left;
	const dropdownWidth = 224; // min-w-56 = 14rem = 224px

	// Set to right if there isn't enough space on the right
	return distanceToRight < dropdownWidth ? 'right' : 'left';
});

// Computed
const popoverId = computed(() => `autocomplete-${props.name}-${useId()}`);

const highlightedOptionId = computed(() => {
	if (highlightedIndex.value === -1) return undefined;
	const option = filteredOptions.value[highlightedIndex.value];
	return option ? `${popoverId.value}-option-${option.value}` : undefined;
});

const displayValue = computed(() => {
	if (props.isError) return $s('Core.Label.ErrorLoadingOptions');

	if (!props.multiselect) {
		const selectedOption = props.options.find(
			(opt) => opt.value === props.modelValue,
		);
		if (!selectedOption) return '';
		return props.displayFormatter
			? props.displayFormatter(selectedOption)
			: selectedOption.label;
	}

	if (!Array.isArray(props.modelValue) || props.modelValue.length === 0)
		return '';

	// Create a Map for O(1) lookups instead of filtering array
	const optionsMap = new Map<string | number, string>(
		props.options.map((opt) => [
			opt.value,
			props.displayFormatter ? props.displayFormatter(opt) : opt.label,
		]),
	);
	return props.modelValue
		.map((value) => optionsMap.get(value))
		.filter(Boolean)
		.join(', ');
});

const filteredOptions = computed(() => {
	// Skip computation if popover is closed
	if (!isOpen.value) return [];

	// Cache the query for better performance
	const query = searchQuery.value.toLowerCase();
	const options = props.options;

	// Early return if no search query
	if (!query) {
		return options.filter((option) => !option?.attrs?.deleted);
	}

	// Use more performant array methods
	return options.filter((option) => {
		if (option.attrs.deleted) return false;

		// Check label first as it's the most common case
		if (option.label.toLowerCase().includes(query)) return true;

		// Only check description if needed and if the function exists
		return (
			props.description &&
			props.description(option.attrs)?.toLowerCase().includes(query)
		);
	});
});

const selectedValuesSet = computed(() => {
	if (!props.multiselect) return null;
	return new Set(Array.isArray(props.modelValue) ? props.modelValue : []);
});

// Toggle popover
function togglePopover() {
	if (isOpen.value) {
		closePopover();
	} else {
		openPopover();
	}
}

function closePopover() {
	popoverRef.value?.hidePopover();
	isOpen.value = false;
}

function getInitialHighlightIndex(): number {
	// If we're searching, always highlight first option
	if (searchQuery.value) {
		return filteredOptions.value.length > 0 ? 0 : -1;
	}

	// For multiselect, find the last selected value
	if (
		props.multiselect &&
		Array.isArray(props.modelValue) &&
		props.modelValue.length > 0
	) {
		const lastSelectedValue = props.modelValue[props.modelValue.length - 1];
		const index = filteredOptions.value.findIndex(
			(opt) => opt.value === lastSelectedValue,
		);
		return index >= 0 ? index : 0;
	}

	// For single select, find the selected value
	if (!props.multiselect && props.modelValue) {
		const index = filteredOptions.value.findIndex(
			(opt) => opt.value === props.modelValue,
		);
		return index >= 0 ? index : 0;
	}

	// Default to first option if available
	return filteredOptions.value.length > 0 ? 0 : -1;
}

function scrollHighlightedIntoView() {
	if (!props.scrollToHighlighted) return;

	nextTick(() => {
		// Custom implementation to avoid body scroll
		const option = optionRefs.value[highlightedIndex.value];
		const container = popoverRef.value?.querySelector(
			`#${popoverId.value}-listbox`,
		);
		if (!option || !container) return;

		const optionRect = option.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();

		if (optionRect.bottom > containerRect.bottom) {
			container.scrollTop +=
				optionRect.bottom -
				containerRect.bottom +
				(props.showCreate ? 40 : 0);
		} else if (optionRect.top < containerRect.top) {
			container.scrollTop -= containerRect.top - optionRect.top;
		}
	});
}

function openPopover() {
	// Don't open if we're intentionally closing
	if (isIntentionalClose.value) return;

	isOpen.value = true;
	nextTick(() => {
		updatePosition();
		popoverRef.value?.showPopover();

		// Prevent scroll when focusing the search input
		if (searchInputRef.value) {
			const currentScroll = window.scrollY;
			searchInputRef.value.focus();
			window.scrollTo(0, currentScroll);
		}

		// Set initial highlight
		highlightedIndex.value = getInitialHighlightIndex();

		// Scroll the highlighted option into view if needed
		scrollHighlightedIntoView();
	});
}

// Update popoverStyle to handle scrolling correctly
function updatePosition() {
	if (!containerRef.value || !popoverRef.value) return;

	// Cache DOM reads
	const popoverStyle = popoverRef.value.style;
	const rect = containerRef.value.getBoundingClientRect();
	const scrollY = window.scrollY;
	const scrollbarWidth =
		window.innerWidth - document.documentElement.clientWidth;

	requestAnimationFrame(() => {
		popoverStyle.top = `${rect.bottom + scrollY + 4}px`;
		popoverStyle[computedOrigin.value] =
			`${computedOrigin.value === 'left' ? rect.left : window.innerWidth - rect.right - scrollbarWidth}px`;
		popoverStyle.width = `${rect.width}px`;
	});
}

// Helper function to check if an option is selected
function isSelected(optionId: string | number): boolean {
	if (props.multiselect) {
		return selectedValuesSet.value?.has(optionId) ?? false;
	}
	return props.modelValue === optionId;
}

// Updated select function to maintain highlight position
function selectOption(option: DropdownItem<TOptionType>) {
	const newValue = props.multiselect
		? handleMultiSelect(option)
		: handleSingleSelect(option);

	emit('update:modelValue', newValue);
	searchQuery.value = '';

	if (!props.multiselect) {
		isIntentionalClose.value = true;
		closePopover();
		setTimeout(() => {
			mainButtonRef.value?.focus();
			// Reset the flag after a short delay
			isIntentionalClose.value = false;
		}, 100);
	}
}

function handleMultiSelect(option: DropdownItem<TOptionType>) {
	const currentValue = Array.isArray(props.modelValue)
		? props.modelValue
		: [];
	return currentValue.includes(option.value)
		? currentValue.filter((id) => id !== option.value)
		: [...currentValue, option.value];
}

function handleSingleSelect(option: DropdownItem<TOptionType>) {
	return props.modelValue === option.value ? null : option.value;
}

function handleCreate() {
	emit('create', searchQuery.value);
	closePopover();
}

function navigateOptions(direction: 'next' | 'prev') {
	const maxIndex = filteredOptions.value.length - 1;
	if (maxIndex < 0) return;

	// If no option is highlighted yet, start from the selected value's position
	if (highlightedIndex.value === -1) {
		highlightedIndex.value = getInitialHighlightIndex();
		searchInputRef.value?.focus();
		return;
	}

	if (direction === 'next') {
		// Cycle to first option when reaching the end
		highlightedIndex.value =
			highlightedIndex.value >= maxIndex ? 0 : highlightedIndex.value + 1;
	} else {
		if (document.activeElement === createButtonRef.value) {
			// From create button, go to last option
			highlightedIndex.value = maxIndex;
		} else {
			highlightedIndex.value =
				highlightedIndex.value <= 0
					? maxIndex
					: highlightedIndex.value - 1;
		}
	}

	// Always ensure search input maintains focus
	searchInputRef.value?.focus();

	// Scroll the highlighted option into view
	scrollHighlightedIntoView();
}

function selectHighlightedOption() {
	if (highlightedIndex.value === -1) return;
	const option = filteredOptions.value[highlightedIndex.value];
	if (option) {
		selectOption(option);
	}
}

function handleTabNavigation(event: KeyboardEvent) {
	if (!isOpen.value) return;

	// Make the main button temporarily unfocusable
	if (mainButtonRef.value) {
		mainButtonRef.value.setAttribute('tabindex', '-1');

		// Reset after the tab navigation is complete
		setTimeout(() => {
			mainButtonRef.value?.setAttribute('tabindex', '0');
		}, 100);
	}

	closePopover();
}

// ESC key handler function
function handleEscapeKey(e: KeyboardEvent) {
	if (e.key === 'Escape' && isOpen.value) {
		e.stopPropagation();
		e.preventDefault();
		closePopover();
	}
}

// Lifecycle
onMounted(() => {
	// Initial position
	updatePosition();

	// Add event listeners with passive option for better performance
	window.addEventListener('scroll', updatePosition, { passive: true });
	window.addEventListener('resize', updatePosition, { passive: true });

	// Add scroll listeners to all parent scrollable elements
	let parent = containerRef.value?.parentElement;
	while (parent) {
		if (isScrollable(parent)) {
			parent.addEventListener('scroll', updatePosition, {
				passive: true,
			});
		}
		parent = parent.parentElement;
	}

	// Click outside handler
	window.addEventListener('click', (e: MouseEvent) => {
		if (!isOpen.value) return;

		const target = e.target as Node;
		const clickedContainer = containerRef.value?.contains(target);
		const clickedPopover = popoverRef.value?.contains(target);

		if (!clickedContainer && !clickedPopover) {
			closePopover();
		}
	});

	if (props.openOnMount) {
		setTimeout(() => {
			openPopover();
		});
	}

	// Modified tab navigation handler - attach to search input
	searchInputRef.value?.addEventListener('keydown', (e: KeyboardEvent) => {
		if (e.key === 'Tab') {
			handleTabNavigation(e);
		}
	});

	// Add ESC key handler
	containerRef.value?.addEventListener('keydown', handleEscapeKey, true);
});

onBeforeUnmount(() => {
	const cleanup = () => {
		window.removeEventListener('scroll', updatePosition);
		window.removeEventListener('resize', updatePosition);

		// Remove ESC key handler
		containerRef.value?.removeEventListener(
			'keydown',
			handleEscapeKey,
			true,
		);

		let parent = containerRef.value?.parentElement;
		while (parent) {
			if (isScrollable(parent)) {
				parent.removeEventListener('scroll', updatePosition);
			}
			parent = parent.parentElement;
		}
	};

	cleanup();
});

// Helper function to check if an element is scrollable
function isScrollable(element: HTMLElement): boolean {
	const hasScrollableContent = element.scrollHeight > element.clientHeight;
	const overflowYStyle = window.getComputedStyle(element).overflowY;
	return (
		hasScrollableContent &&
		(overflowYStyle === 'auto' || overflowYStyle === 'scroll')
	);
}

// Add debounced search emit
const debouncedSearch = useDebounceFn((query: string) => {
	emit('search', query);
}, 300);

function getInitials(fullName: string) {
	return $toInitials(fullName, { maxLength: 2 });
}

function getTextColorClass(fullName: string) {
	const initials = getInitials(fullName);
	return $textToColor(initials);
}

// Update search handler
watch(searchQuery, (newQuery) => {
	debouncedSearch(newQuery);
	// Reset highlight to first option when searching
	highlightedIndex.value = filteredOptions.value.length > 0 ? 0 : -1;
});

// Add error handling
watch(
	() => props.isError,
	(newValue) => {
		if (newValue) {
			closePopover();
		}
	},
);

// Add disabled handling
watch(
	() => props.disabled,
	(newValue) => {
		if (newValue) {
			closePopover();
		}
	},
);

watch(isOpen, (newValue, oldValue) => {
	if (!newValue && oldValue) {
		// Clean up when popover closes
		searchQuery.value = '';
		highlightedIndex.value = -1;
	}
	emit('onPopoverToggle', newValue);
});

defineExpose({
	togglePopover,
});
</script>

<style scoped>
.ax-popovertarget {
	inset: unset;
}
</style>
