<template>
	<div class="group relative z-10 h-12 w-12 sm:h-20 sm:w-20">
		<div
			v-if="isPending"
			class="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center"
		>
			<LoadingBubbles />
		</div>

		<Menu v-if="context?._value" as="div" class="relative">
			<MenuButton as="div" class="h-12 w-12 sm:h-20 sm:w-20">
				<Image
					:src="context?._value"
					class="h-full w-full rounded-full object-cover transition-all"
					width="80"
					height="80"
					:class="{
						'opacity-80': isPending,
						'opacity-100': !isPending,
					}"
				/>
			</MenuButton>

			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95"
			>
				<MenuItems
					class="ax-popover absolute left-0 z-20 mt-2 w-40 origin-top-left focus:outline-hidden"
				>
					<div class="p-1">
						<MenuItem v-slot="{ active }">
							<button
								type="button"
								class="group flex w-full items-center gap-x-2 rounded-sm px-3 py-1.5 text-sm transition-colors"
								:class="{
									'bg-slate-100': active,
								}"
								@click="triggerFileSelect"
							>
								<Icon
									:name="ICON_UPLOAD"
									class="h-4 w-4 shrink-0"
									aria-hidden="true"
								/>
								<p>{{ $s('Core.Button.UploadImage') }}</p>
							</button>
						</MenuItem>
						<MenuItem v-slot="{ active }">
							<button
								type="button"
								class="group flex w-full items-center gap-x-2 rounded-sm px-3 py-1.5 text-sm text-red-600 transition-colors"
								:class="{
									'bg-red-50': active,
								}"
								@click="removeFile"
							>
								<Icon
									:name="ICON_TRASH"
									class="h-4 w-4 shrink-0"
									aria-hidden="true"
								/>
								<p>{{ $s('Core.Button.Remove') }}</p>
							</button>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>

		<span
			v-if="context?._value"
			class="pointer-events-none absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-slate-900 bg-opacity-50 text-white opacity-0 transition-opacity group-hover:opacity-100"
		>
			<Icon
				:name="ICON_EDIT"
				class="h-7 w-7 sm:h-9 sm:w-9"
				aria-hidden="true"
			/>
		</span>

		<button
			v-if="!context?._value"
			type="button"
			class="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-slate-900 bg-opacity-50 text-white opacity-0 transition-opacity group-hover:opacity-100"
			@click="triggerFileSelect"
		>
			<Icon
				:name="ICON_UPLOAD"
				class="h-7 w-7 sm:h-9 sm:w-9"
				aria-hidden="true"
			/>
		</button>
	</div>

	<input
		:id="context?.id"
		type="file"
		class="hidden"
		accept="image/*"
		@change="onFileSelected"
	/>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import ModalCrop from '~/components/Modal/ModalCrop.vue';

const props = defineProps({
	context: Object,
});

const emit = defineEmits(['update:modelValue']);

const file = ref<File | null>(null);
const fileBlob = ref<string | null>(null);
const fileType = ref<string | null>(null);

function handleInput(value: string | null) {
	emit('update:modelValue', value);
	props.context?.node.input(value);
}

function triggerFileSelect() {
	const input = document.getElementById(
		props.context!.id
	) as HTMLInputElement;

	// Clean up the file input
	file.value = null;
	fileBlob.value = null;
	fileType.value = null;
	input.value = '';

	input.click();
}

const { upload, isPending } = useImageUpload();
const { openModal } = useAsyncModal<boolean>({
	component: ModalCrop,
});
async function onFileSelected(event: Event) {
	const target = event.target as HTMLInputElement;
	const fileList = target.files;

	const selectedFile = fileList?.[0];
	if (!selectedFile) {
		return;
	}

	file.value = selectedFile;
	fileBlob.value = URL.createObjectURL(selectedFile);
	fileType.value = selectedFile.type;

	const { payload: croppedFile } = await openModal({
		file: selectedFile,
	});

	if (!croppedFile) {
		return;
	}

	const uploadedFile = await upload(croppedFile);
	handleInput(uploadedFile.fileUrl!);
}

function removeFile() {
	handleInput(null);
}
</script>
