import { NuxtError } from 'nuxt/app';
import { AxosError } from '~/types/axos-api';

export enum ToastType {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
}

interface Toast {
	id: string;
	message: string;
	type: ToastType;
	timeout?: number;
	isExiting?: boolean;
}

export default function () {
	const toasts = useState<Toast[]>('appToasts', () => []);

	function showToast(
		message: string,
		type: ToastType = ToastType.SUCCESS,
		timeout = 5000
	) {
		const id = Math.random().toString(36).substr(2, 9);
		const localizedMessage = message;
		toasts.value.push({ id, message: localizedMessage, type, timeout });
		setTimeout(() => removeToast(id), timeout);
	}

	function removeToast(id: string) {
		const toast = toasts.value.find((t) => t.id === id);
		if (toast) {
			toast.isExiting = true;
			setTimeout(() => {
				toasts.value = toasts.value.filter((t) => t.id !== id);
			}, 500); // Match the animation duration
		}
	}

	function showSuccess(message: string, timeout?: number) {
		showToast(message, ToastType.SUCCESS, timeout);
	}

	function showError(
		error: AxosError | NuxtError | string | any,
		timeout: number = 10000
	) {
		if (typeof error === 'string') {
			showToast(error, ToastType.ERROR, timeout);
		} else {
			showToast(
				error?.resourceId ?? error?.message ?? 'En feil har oppstått',
				ToastType.ERROR,
				timeout
			);
		}
	}

	function showWarning(message: string, timeout?: number) {
		showToast(message, ToastType.WARNING, timeout);
	}

	function showInfo(message: string, timeout?: number) {
		showToast(message, ToastType.INFO, timeout);
	}

	return {
		toasts,
		show: showToast,
		remove: removeToast,
		success: showSuccess,
		$success: showSuccess,
		error: showError,
		$error: showError,
		warning: showWarning,
		$warning: showWarning,
		info: showInfo,
		$info: showInfo,
	};
}
