import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU from "/app/.nuxt/formkitPlugin.mjs";
import a_vue_query_3ayk6R4b5_nBXmu_WaeWi2sP_SXa_FSTioDNqXFYC5k from "/app/plugins/a-vue-query.ts";
import dompurify_QdFbGyOD5_QMbw84hfDVj_iIhoq7JScF_Mk_91FqSbE from "/app/plugins/dompurify.ts";
import graphql_request_client_z_bBci0MWJkIny4auiXJX7G5aXVLf_WH2Xn0bdob7rc from "/app/plugins/graphql-request-client.ts";
import highcharts_xW1TWoXEAp0SkAt1isO2ctP7ms_J4XbPbB3PNanKlQc from "/app/plugins/highcharts.ts";
import pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y from "/app/plugins/pinia.ts";
import sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM from "/app/plugins/sentry.ts";
import signalr_c1_V7uVbglKQFu99EHIUIf6kT2ky6rAICIuE8tTBUxQ from "/app/plugins/signalr.ts";
import vue_final_modal_r3hWN1g3fjLRxtJ_rTg5dKijwBD5cViPOKcrr2biDj0 from "/app/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU,
  a_vue_query_3ayk6R4b5_nBXmu_WaeWi2sP_SXa_FSTioDNqXFYC5k,
  dompurify_QdFbGyOD5_QMbw84hfDVj_iIhoq7JScF_Mk_91FqSbE,
  graphql_request_client_z_bBci0MWJkIny4auiXJX7G5aXVLf_WH2Xn0bdob7rc,
  highcharts_xW1TWoXEAp0SkAt1isO2ctP7ms_J4XbPbB3PNanKlQc,
  pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y,
  sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM,
  signalr_c1_V7uVbglKQFu99EHIUIf6kT2ky6rAICIuE8tTBUxQ,
  vue_final_modal_r3hWN1g3fjLRxtJ_rTg5dKijwBD5cViPOKcrr2biDj0
]