<template>
	<li>
		<Popover class="z-30 flex flex-col items-start" v-slot="{ open }">
			<transition
				enter-active-class="transition duration-150 ease-out"
				enter-from-class="translate-y-2 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-150 ease-in"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="translate-y-2 opacity-0"
			>
				<PopoverPanel
					class="fixed bottom-2 left-20 z-10 max-h-screen w-full max-w-[calc(100%-5.5rem)] overflow-auto text-slate-500 sm:bottom-10 sm:left-10 sm:w-[40rem]"
				>
					<Section class="max-h-screen overflow-y-auto shadow-xl">
						<h2 class="mb-4 text-center text-xl font-bold">
							<span v-if="!formSubmitted">
								{{ $s('Idea.Header.IdeaOrFeedback') }}
							</span>
							<span v-else>
								{{ $s('Idea.Header.Thanks') }}
							</span>
						</h2>

						<FormKit
							v-if="!formSubmitted"
							type="form"
							ref="form"
							@submit="submit"
							:actions="false"
						>
							<div class="mb-1 flex flex-col">
								<FormKit
									type="text"
									name="title"
									:label="$s('Idea.Label.Title')"
									validation="required"
									autofocus
								/>

								<FormKit
									type="textarea"
									name="description"
									:label="$s('Idea.Label.Description')"
								/>

								<FormKit
									type="dropdown"
									name="importanceTypeId"
									:label="$s('Idea.Label.Importance')"
									:options-loader="
										getIdeaImportanceTypeOptions
									"
									:option-loader="getIdeaImportanceTypeOption"
									validation="required"
								/>
								<FormKit
									type="file"
									name="attachments"
									:label="$s('Idea.Label.Attachments')"
									accept="image/*"
									:help="
										$s('Idea.Label.AttachmentsDescription')
									"
								/>
							</div>
							<FormKit type="submit">
								{{ $s('Core.Button.Submit') }}
							</FormKit>
						</FormKit>

						<div v-else class="text-center">
							<Button
								class="mt-4"
								full-width
								theme="neutral"
								@click="reset"
							>
								{{ $s('Idea.Button.SubmitNewIdea') }}
							</Button>
						</div>

						<div
							class="-mx-5 mt-4 -mb-3 flex flex-col justify-between gap-x-6 gap-y-2 rounded-md border bg-slate-75 p-4 sm:flex-row"
						>
							<div class="flex w-full">
								<Button
									size="small"
									theme="light"
									class="pl-0"
									link
									to="/ideas/my-ideas"
									full-width
									icon-placement="right"
									:class="{
										'rounded-r-none': isAdmin,
									}"
								>
									{{ $s('Idea.Label.MyIdeas') }}
								</Button>

								<Button
									v-if="isAdmin"
									theme="light"
									size="small"
									class="-ml-px rounded-l-none"
									link
									to="/ideas/list"
									full-width
									icon-placement="right"
								>
									{{ $s('Idea.Label.AllIdeas') }}
								</Button>
							</div>

							<Button
								theme="secondary"
								size="small"
								link
								to="/release-log"
								full-width
								icon="ph:sparkle-duotone"
							>
								{{ $s('Idea.Button.ReleaseLog') }}
							</Button>
						</div>
					</Section>
				</PopoverPanel>
			</transition>

			<PopoverButton
				class="group/tooltip ax-sidemenu-item"
				:class="{
					'border-slate-800 bg-slate-800/60 text-white':
						open || isActive('/ideas'),
					'border-transparent text-[var(--app-sidemenu-icon)]':
						!open && !isActive('/ideas'),
				}"
			>
				<Icon
					v-if="!open"
					:name="ICON_IDEA"
					class="h-6 w-6 scale-x-[-1]"
				/>
				<Icon v-else :name="ICON_X" class="h-6 w-6" />

				<span v-if="showLabels">
					{{ $p('Core.Navigation.Ideas') }}
				</span>

				<Tooltip v-else>
					{{ $p('Core.Navigation.Ideas') }}
				</Tooltip>
			</PopoverButton>
		</Popover>
	</li>
</template>
<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { IdeaInput } from '~/types/axos-api';

const { isAdmin, isDeveloper } = useAccessControl();

const props = defineProps<{
	showLabels?: boolean;
}>();

const emit = defineEmits<{
	(e: 'submit'): void;
}>();

const {
	ideaImportanceTypes,
	getIdeaImportanceTypeOption,
	getIdeaImportanceTypeOptions,
} = useDropdownIdeaImportanceType();

const formSubmitted = ref(false);

const { invalidate: invalidateIdeas } = useInvalidateQuery(['ideasFromMe']);

const { form, reset: resetForm } = useForm();
const { create, onSuccess } = useIdeaCreate();

onSuccess(() => {
	emit('submit');
	$success($s('Idea.Info.Submitted'));
	formSubmitted.value = true;
});

const { upload } = useImageUpload();

async function submit(data: IdeaInput & { attachments?: any[] }) {
	const file: File = data.attachments?.[0]?.file;
	if (file) {
		const fileRes = await upload(file);
		data.attachmentId = fileRes.uniqueGuid;
	}

	delete data.attachments;

	invalidateIdeas();

	create({ input: data });
}

function reset() {
	resetForm();
	formSubmitted.value = false;
}

const route = useRoute();
function isActive(path: string) {
	return route.path.startsWith(path);
}
</script>
