
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as me0y3uh_jnqQ_451fAuFw_c3zkHcfRva3ChRdo5ufeaI8JkMeta } from "/app/pages/me.vue?macro=true";
import { default as indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta } from "/app/pages/admin/index.vue?macro=true";
import { default as modules4OO_5zVxEo3uyTz7yV2jvqmdzVcMrUoZwqQ0IgzeCEEMeta } from "/app/pages/admin/modules.vue?macro=true";
import { default as instanceBFw_451nLXFJ_45gTEpRk_45XWwoCq1FrkkZg4msrkQWVuZhAMeta } from "/app/pages/admin/instance.vue?macro=true";
import { default as entitiesxMIr64RgoshYzJ84lewiSajkG3vLdw7xgQdppb9_45bUoMeta } from "/app/pages/admin/developer/entities.vue?macro=true";
import { default as broadcastIOxgcARqMgM3hxwqI8ptddPHUQbHWnik1NoF4MTuoPkMeta } from "/app/pages/admin/developer/broadcast.vue?macro=true";
import { default as languageshnEUlgLPv3PyVOQtSwOAei3Xu6bM4aQKIxWAGqN5XWgMeta } from "/app/pages/admin/developer/languages.vue?macro=true";
import { default as provisioninghCnaxmzfr4KMnYcXVDtjTR0m_eA_PtMCYaZ31kpEwksMeta } from "/app/pages/admin/developer/provisioning.vue?macro=true";
import { default as adminHy0fZKw3Hs3Ilpe9PIV9_Obs0tEAeICxhGLuXJq_45_SMMeta } from "/app/pages/admin.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as searchWiCwPORWRkcZm1zH29UWKNMF4Vb5hM_q5Mi2yI40hlcMeta } from "/app/pages/search.vue?macro=true";
import { default as indexD9PRL_ab4tjOfaS9h5O_xCCciiGkJ33MJf_45OPBujF20Meta } from "/app/pages/hr/index.vue?macro=true";
import { default as newHR37xvxotctkXc2WDkD5JnD0ycrzRJAXqul4_dfIxiAMeta } from "/app/pages/news/new.vue?macro=true";
import { default as index3LnkHi40moMjd3IeWF_45c_45jAvjerQHjF5CuG081TisucMeta } from "/app/pages/crm/index.vue?macro=true";
import { default as newiocmtq9ha1xegc_XeZ1g271sPYr3WlemX4KxK65UsQMMeta } from "/app/pages/leads/new.vue?macro=true";
import { default as error_45testcua7Cqf2CRzPWe2X_45y5hKkvvtzT2mBuPXGYw4LHWZPgMeta } from "/app/pages/error-test.vue?macro=true";
import { default as indexkpkhE2Zx_45wVJqyj11FyNpnGQQa93xlI5j2_nUvO6G4wMeta } from "/app/pages/news/index.vue?macro=true";
import { default as clientL1ACq8SiAVlGCqq_45fbbrW1HV_4593blxHb_6ct8sup6qkMeta } from "/app/pages/auth/client.vue?macro=true";
import { default as cm_45settingsEHGa8jsbhhLOGrs5lArucmRRakInWuhPH6GhAAZM6Q8Meta } from "/app/pages/cm-settings.vue?macro=true";
import { default as workdays7JKCRGPzCzLgzkgHp1waXpwAooKoxWTLUmXJ7VWTiH4Meta } from "/app/pages/hr/settings/employees/workdays.vue?macro=true";
import { default as positionsn5XIxLV_45IFLS8NziqnmKbhPhl4GKA4J9sN8ru83ZDTUMeta } from "/app/pages/hr/settings/employees/positions.vue?macro=true";
import { default as size_45feetTK9bgYuI_jmiP9YIK9DIaTZ3YJekYgtQdIJwNpACfI0Meta } from "/app/pages/hr/settings/employees/size-feet.vue?macro=true";
import { default as size_45legsRok5SgOyy3aZp94dzRbuKg950NYBbAf9I4tC_CJNUgEMeta } from "/app/pages/hr/settings/employees/size-legs.vue?macro=true";
import { default as size_45torsoRg77zL5AV3nEOeVfYxllsBtIYoki1d_kVKYqNRif3xQMeta } from "/app/pages/hr/settings/employees/size-torso.vue?macro=true";
import { default as size_45waistDEjGsaG_S7h7EI0ZvT8h0foX8ge4MxJahsEwtiC17FoMeta } from "/app/pages/hr/settings/employees/size-waist.vue?macro=true";
import { default as allergy_45typesMHiAOzqd_45P3Rs_K99fqEKHwWCV7hLrdSOpkezGdnRZ4Meta } from "/app/pages/hr/settings/employees/allergy-types.vue?macro=true";
import { default as custom_45fieldsKOeidJjabCH6SeF3OKlxDpoaDRHEMXiPgQq0MGBoSfAMeta } from "/app/pages/hr/settings/employees/custom-fields.vue?macro=true";
import { default as competence_45typesY92IxJgZOb26mv8lufKgXLtxb52Kb5AXL3OSNclI2k4Meta } from "/app/pages/hr/settings/employees/competence-types.vue?macro=true";
import { default as employment_45typesB8oFogTEcRt_BTkNl824qrPexg9TFg3dlLNI2asLLeoMeta } from "/app/pages/hr/settings/employees/employment-types.vue?macro=true";
import { default as certificate_45typesco6Jk6pZVvcOCrWmnKB_bY8nq62Belii3FBhj0drTAkMeta } from "/app/pages/hr/settings/employees/certificate-types.vue?macro=true";
import { default as employment_45wage_45typesoUkwErpUx0CWEe7age2DClZhy1Gdd3UlK_45TlEcRX9dUMeta } from "/app/pages/hr/settings/employees/employment-wage-types.vue?macro=true";
import { default as family_45relation_45typesUvRoHgyB_mIrJtwInMQd2tBNioO1IQY6gFOE2sd3khcMeta } from "/app/pages/hr/settings/employees/family-relation-types.vue?macro=true";
import { default as settingsYKCxhKjYrCxAvekHqcpUfa3JI9UYMOkqpHrHSy6SLysMeta } from "/app/pages/hr/settings.vue?macro=true";
import { default as indexGsSNRXRP7zBEQJt_45XvmmeJN2c84IjkADYcHArlW1qQcMeta } from "/app/pages/ideas/index.vue?macro=true";
import { default as indexkFvFao0mf7UN3hyJuK0wU7vluZ7uf39m_45YUtyJJyVocMeta } from "/app/pages/leads/index.vue?macro=true";
import { default as indexNygbVzUI3a_45M5EoPYwngia4uRcIo9Ma7K9JNY4ORrWQMeta } from "/app/pages/links/index.vue?macro=true";
import { default as login_45errorriBE_ZmPcHv0oYWXLNF4pk28__tWa1C4xUGGBWjQc_45kMeta } from "/app/pages/login-error.vue?macro=true";
import { default as draftsdxhaRaGZgXs3TQqvqd_n8D7Ka5IsYFGC1CmWAIBeYE0Meta } from "/app/pages/news/drafts.vue?macro=true";
import { default as release_45logNr_45j1t_5VF9C8z4prDnoS_45DiXDilobvsewPESy5oDXIMeta } from "/app/pages/release-log.vue?macro=true";
import { default as newdpw2ifalPdRWi8OtLToDNiJtDFFOYdPxoWBS4OVy56gMeta } from "/app/pages/accounts/new.vue?macro=true";
import { default as newQgIV4XXAeB9a2P9UmP4unN_45wt4D_fuIvBLYvSIWJvhUMeta } from "/app/pages/contacts/new.vue?macro=true";
import { default as settingsb2_45hqlsseoWF_45uiqzblOfKtcJAgrZyJmuxXOutEaTmcMeta } from "/app/pages/crm/settings/gdpr/settings.vue?macro=true";
import { default as typeszEFSawjILjheJKznTHfsfnk0zLjVl1fVP3_45WnaHmQM8Meta } from "/app/pages/crm/settings/accounts/types.vue?macro=true";
import { default as campaign_45monitorb58jKOnhgmsIm5ewTzgaYFb2iYpvgC39SI1mOqxEAYsMeta } from "/app/pages/crm/settings/campaign-monitor.vue?macro=true";
import { default as positionss8fpBJ6dQqO9My_sFMElfHb2hTCInzaq6lFMkp_u7VoMeta } from "/app/pages/crm/settings/contacts/positions.vue?macro=true";
import { default as consent_45typesFfdyeZe1hWtfzrZEHGYYGkWQak3kEsFsqlmo0guH2q8Meta } from "/app/pages/crm/settings/gdpr/consent-types.vue?macro=true";
import { default as industriesITppN5qA3828MuHhe9mJX0ochLAUUSkgmy_HxIAHkYAMeta } from "/app/pages/crm/settings/accounts/industries.vue?macro=true";
import { default as custom_45fieldsP72xJtVJ5LUB3Z1JhpOEBDrv0S4oYx4wj1ZtjzSoX_45wMeta } from "/app/pages/crm/settings/accounts/custom-fields.vue?macro=true";
import { default as custom_45fieldsucH1jOot4_453Nembu9yfuDmsg3puxF8ZwR4Pz4FBzzaQMeta } from "/app/pages/crm/settings/contacts/custom-fields.vue?macro=true";
import { default as custom_45fieldsqOB_45u5BsQ05ykHw1kfdLYRFdD_p59tJ1Llokd3dUJ3IMeta } from "/app/pages/crm/settings/account-meetings/custom-fields.vue?macro=true";
import { default as settings2U85Ts5AO3EJLgNf5Psbu44DS1czSsS3nUzkPiYiaI8Meta } from "/app/pages/crm/settings.vue?macro=true";
import { default as order_45modulekOFbx288qbiOvPyPEelyDgv414ZjH2YzOjHcqjUPJkYMeta } from "/app/pages/order-module.vue?macro=true";
import { default as newqhCiQp1nRwV6EwS6_45oYqOBf9fcyWrjuyVxw6dIKlRsEMeta } from "/app/pages/products/new.vue?macro=true";
import { default as server_45error_TFbMd4gdcl5Vg1kc71HhO0IfM8aNnKdYdZB2EiH7xsMeta } from "/app/pages/server-error.vue?macro=true";
import { default as client7zkWdzYTeeL7M66NVkK3fz4lw4w5RcDZJ5JcqFNlN4IMeta } from "/app/pages/setup/client.vue?macro=true";
import { default as indexKG6JiwNhEbpx3QdDMzByQaNZBJsM8Rxazy9ksmWDRGEMeta } from "/app/pages/slides/index.vue?macro=true";
import { default as workflow_45pocuXVV4pvSEBGtKsXKPPQnfpmLrSC_45Yomd82P6okRU06YMeta } from "/app/pages/workflow-poc.vue?macro=true";
import { default as newI89WaWdNFaTwy9nuTtmkYTdwTLIruLXdDZcQtBMsueYMeta } from "/app/pages/employees/new.vue?macro=true";
import { default as indexib51ezwhx7_45uQtpy_45NYnTqKR9rly09Z2x_ps6YMdXQUMeta } from "/app/pages/news/settings/index.vue?macro=true";
import { default as custom_45fieldsgsPfC5k4qUFaxcUBJG_1bm_T0uExD_45bVNlDk2FqwdtwMeta } from "/app/pages/news/settings/custom-fields.vue?macro=true";
import { default as settingsCqsa8eu78kVbg6S3RwVNvlHXGDO7V3FK38rZz4rUZjsMeta } from "/app/pages/news/settings.vue?macro=true";
import { default as indexn6qc1p3ftV2qG3p0drs6HbQADMirvpNTQqQIGdK5yW8Meta } from "/app/pages/accounts/index.vue?macro=true";
import { default as indexo2_aOKOa6D1FHFGPVtcTzIIV5UU4oyTg8ow_45uVzF3EgMeta } from "/app/pages/contacts/index.vue?macro=true";
import { default as _91ideaId_93ITYNqCqPIcQOW8rJBW_45xG_LymcizcRp5m9b_453GhLzX8Meta } from "/app/pages/ideas/[ideaId].vue?macro=true";
import { default as my_45ideasx5mGwdidSumsw9Jd3pgQ430svE6qETn9EQSepBvaNZIMeta } from "/app/pages/ideas/my-ideas.vue?macro=true";
import { default as fileszPaj4qyJ41gq3hfhXZL8QcTIb77_YPFgyGIgHt618YwMeta } from "/app/pages/leads/[leadId]/files.vue?macro=true";
import { default as detailsLNAB7bmr34fHkYBGObNhReTMlwvTU6yQ_45NS9Vl33wbEMeta } from "/app/pages/leads/[leadId]/details.vue?macro=true";
import { default as historyNUjzYhhEHWeOK_FZ9qjqJxpKvfiThEIxrJvipCwKkkMMeta } from "/app/pages/leads/[leadId]/history.vue?macro=true";
import { default as activities4bIIROiYdD95ic14m0U08xXZsW1plkYXonmT1yNhWCEMeta } from "/app/pages/leads/[leadId]/activities.vue?macro=true";
import { default as _91leadId_938wMfb2PAJ9iIgwVqKehJoolV2pUO6cdPjhEsxzFyJtMMeta } from "/app/pages/leads/[leadId].vue?macro=true";
import { default as categoriesi5dVgIl6EkHqEDuv0Fr4lNqlHI0i2KHTIh018RfWqEoMeta } from "/app/pages/links/settings/categories.vue?macro=true";
import { default as custom_45fieldss15jyD2Sd95Uexzq7wB8I6vUg9DgydoAbbQqPEOFO2MMeta } from "/app/pages/links/settings/custom-fields.vue?macro=true";
import { default as settingsU1s4QFQlSBBazApGIjLi6aL6EM9WdTJE0PV1Lm1hG6UMeta } from "/app/pages/links/settings.vue?macro=true";
import { default as editpo7HBALaE4ffmJALbpwpNyQJkIoODCfmmuQkAeyd0YAMeta } from "/app/pages/news/[id]/edit.vue?macro=true";
import { default as indexCS9TBWZLvVvHfBzyOBaud5vfuMt23eSrxLSN_ubukeEMeta } from "/app/pages/products/index.vue?macro=true";
import { default as rich_45text_45demoEY0PQozRhZSufJWQIjD1EgfdPqZm_45jL5C_PGsuY2C3UMeta } from "/app/pages/rich-text-demo.vue?macro=true";
import { default as ratings8F6KgV4ygz2_45oOQwWKSf7YHEiiPB8e9u6QPtJFuxOKcMeta } from "/app/pages/sales/settings/leads/ratings.vue?macro=true";
import { default as sourcesyQKNJkKq6xI0tgDxAixjGN0ALzUdAymVwZP0XwdjLtUMeta } from "/app/pages/sales/settings/leads/sources.vue?macro=true";
import { default as settingsZznsW0Pe3PPVMPLirvoE5ArhPmgIDWR7jBqyMuiNjyMMeta } from "/app/pages/sales/settings/slides/settings.vue?macro=true";
import { default as categoriesWAQYECYPxCY8QxFeefrDnjQu10DHIVTW9_452zLnUUy60Meta } from "/app/pages/sales/settings/slides/categories.vue?macro=true";
import { default as topic_45typesxP2NaobY4esJoyafHgNkE8N6GXg5cuNbWI13aGsFEWIMeta } from "/app/pages/sales/settings/slides/topic-types.vue?macro=true";
import { default as custom_45fields5826KsPXks3fXK54cX_sWCrZTcRkGMDxRadtwmuk_450EMeta } from "/app/pages/sales/settings/leads/custom-fields.vue?macro=true";
import { default as settingsIhW9FFmbrEpwWGW6TzSU1vByXwU_d5lMdQibTowC39AMeta } from "/app/pages/sales/settings.vue?macro=true";
import { default as indexS10ft8QrdRblpZDKmgLMOEy_45KXi2FEGCpyA2D2wgq_YMeta } from "/app/pages/employees/index.vue?macro=true";
import { default as indexndIrYyk3zjYzjT5YtcOWxJIH6fWe3Wk9_452bNxf7lXB0Meta } from "/app/pages/news/[id]/index.vue?macro=true";
import { default as brands0yo7Cmc_45JEkslhBXB6OiFVGHvDI1qCYDw0ynFZg3pVcMeta } from "/app/pages/finance/settings/products/brands.vue?macro=true";
import { default as metricsI77nZ6yO0ezYoKOgec2K8kCyF1ibJyMpSV2uiciH6w8Meta } from "/app/pages/finance/settings/products/metrics.vue?macro=true";
import { default as categoriesc_453DZDJn7u0kQ48CxIECnJQXLFcNjnxt7dGEq_45gErE4Meta } from "/app/pages/finance/settings/products/categories.vue?macro=true";
import { default as custom_45fieldsrfWtiIohtP5CCw4yd3iHnFljT5luxU5m4YKmJXZiwKoMeta } from "/app/pages/finance/settings/products/custom-fields.vue?macro=true";
import { default as settingsx3SgQcciXByqoB3rPKXYLF7Jj35zJe1AhySrV_45WQETQMeta } from "/app/pages/finance/settings.vue?macro=true";
import { default as newmYUCkeWjZ5ZDrIPd3d_45z6hnMmtdaoUSwEsC6TpFeqbYMeta } from "/app/pages/organisations/new.vue?macro=true";
import { default as indexLAsR_45k5BLgSTNsmel96iNreE2S1wWp8UXBb8cQClWvUMeta } from "/app/pages/organisations/index.vue?macro=true";
import { default as indexRYQmYJdLtiarxDhrfTTCgKkLBTVfIu_XI7DHpbTfrrYMeta } from "/app/pages/projects/[id]/index.vue?macro=true";
import { default as news3fYEVKKeVjzuSqnORO6vlfUvXl6qfQFAtJz0k1L2PAMeta } from "/app/pages/account-meetings/new.vue?macro=true";
import { default as filesriSxTkLVQgt5r2alb__45yN2oD7MNQpgkmFazgi8mI7M8Meta } from "/app/pages/accounts/[accountId]/files.vue?macro=true";
import { default as leadsJ4HNl8B98NG63KibJZQ_45FucJEiP0bvCDuuvptTyyWD4Meta } from "/app/pages/accounts/[accountId]/leads.vue?macro=true";
import { default as details_45YS1zKCrhgaJUCAXtWQv1tLw2OGlKjxufR7EsjUf1LgMeta } from "/app/pages/accounts/[accountId]/details.vue?macro=true";
import { default as historyHXvydfo6x4Xn0cs6wf9fJJoCEy6tmMEw_45HDnhhjyc40Meta } from "/app/pages/accounts/[accountId]/history.vue?macro=true";
import { default as contactskgKWyuxKAF6wpbSFY4SPtTqXVWrrMkGtmBMoD67vMFcMeta } from "/app/pages/accounts/[accountId]/contacts.vue?macro=true";
import { default as meetingsreyw6STXbFGDgb2AcM40zOFJN3nwz4x3433eCyKBUiYMeta } from "/app/pages/accounts/[accountId]/meetings.vue?macro=true";
import { default as addressesFikBpnwTANGYk9wp1Jl8uOT9chbF4Rb9nmOYW1WCPmcMeta } from "/app/pages/accounts/[accountId]/addresses.vue?macro=true";
import { default as activities1r1YMUo1xm8raUP3ytP6JWz_klT2ZdVcCy67lYTkDXMMeta } from "/app/pages/accounts/[accountId]/activities.vue?macro=true";
import { default as _91accountId_93sLR0h65AuqlAI99xsoXtGvDl_A9AfOTs9014qtgFBsIMeta } from "/app/pages/accounts/[accountId].vue?macro=true";
import { default as filesb7CfpcGNx0a_45JjdeeCKyqCDEO6W_454PYzfQCMjQpc2mAMeta } from "/app/pages/contacts/[contactId]/files.vue?macro=true";
import { default as leads2X5mSSvf8rY_45eRR5vShp1NasMBy__cQJS8Foff5KuckMeta } from "/app/pages/contacts/[contactId]/leads.vue?macro=true";
import { default as detailsoYlwwGFyddw_DUUXF__45KcpMVqqPsPEsth3_P_xNTiXoMeta } from "/app/pages/contacts/[contactId]/details.vue?macro=true";
import { default as historyQ5iGUlgpf3Oc0xHgc3VsF9KhebVv536Z4I2adnMA0BoMeta } from "/app/pages/contacts/[contactId]/history.vue?macro=true";
import { default as consentsts8KWVQIcluIxUIdPlmk8ud2eP5ICXuh6QEsmWcn_45aAMeta } from "/app/pages/contacts/[contactId]/consents.vue?macro=true";
import { default as meetingsMTEMY7e31Jtr5yncHEKe_GgL2uhpJwqLPF55njc09FYMeta } from "/app/pages/contacts/[contactId]/meetings.vue?macro=true";
import { default as activitiesFWEh1zhDDsblvoqx_45WIZU8TqxLHOnXei3WWvFwG613AMeta } from "/app/pages/contacts/[contactId]/activities.vue?macro=true";
import { default as social_45handlesktCf3S6Y8xU213eLAcNCuUDgnLignPXlfyfcVmoykhIMeta } from "/app/pages/contacts/[contactId]/social-handles.vue?macro=true";
import { default as _91contactId_93Qn_45cUFxZBsaylWgIQdn_tY6HbQTeqIfwvtIKtMXq_iAMeta } from "/app/pages/contacts/[contactId].vue?macro=true";
import { default as no_45clients_45availabledlhjoj8TCQTFVkjiiQauy2nRuL1w_StIQM6E_45YOO9DsMeta } from "/app/pages/no-clients-available.vue?macro=true";
import { default as filesSvd_45K1xpGkRSdQYEa8ePQNuSS30DZwdrTl9fo1b8_452UMeta } from "/app/pages/products/[productId]/files.vue?macro=true";
import { default as detailsaA4m6WWOZ5uSRnwFiWfFJZVThxOhy_45Lt6lvjUsSA4_45MMeta } from "/app/pages/products/[productId]/details.vue?macro=true";
import { default as historyeXFlL5m2zfstrRbShUhxXqT6cMmu_TDvoR5HlOD_45WYsMeta } from "/app/pages/products/[productId]/history.vue?macro=true";
import { default as _91productId_93_45nFhuH8hyYICLifW4T8d4GW6_5_2_5mCqwOJX2XgJ3cMeta } from "/app/pages/products/[productId].vue?macro=true";
import { default as indexSquJzMzf4gJ3jJa9nyhODrc_paOwaUKoNIWJLhPClwkMeta } from "/app/pages/account-meetings/index.vue?macro=true";
import { default as files_3jKPSRXACbSfa5jGFUqXslUkAE9hTbYjkiHDRZedw0Meta } from "/app/pages/employees/[employeeId]/files.vue?macro=true";
import { default as leadsQ8vGFGO_45NE6IU47T57EET0xMfHHfn8S565BTIQNKxi0Meta } from "/app/pages/employees/[employeeId]/leads.vue?macro=true";
import { default as teamszFprV9NXCTaq8BLkzniqBBfUWu3Iwz4jJLah4qN3w18Meta } from "/app/pages/employees/[employeeId]/teams.vue?macro=true";
import { default as familyFjQRuPxHgfL1kqsCuQSkL8iDY5R5WJ9R2iD0PacbmXUMeta } from "/app/pages/employees/[employeeId]/family.vue?macro=true";
import { default as absencesPRGqKjLdFrJnRrtqFVuaZEbupRdAeomeMolpq1ls9EMeta } from "/app/pages/employees/[employeeId]/absence.vue?macro=true";
import { default as detailsmpNomdI_j4usEt0lmmOtpQAQQ1tXn3s_U9TkRRcpYb0Meta } from "/app/pages/employees/[employeeId]/details.vue?macro=true";
import { default as devicesYQsJaCkobhjvy_qvdmC2cZj5_tA11ASR23vgWxvVnrwMeta } from "/app/pages/employees/[employeeId]/devices.vue?macro=true";
import { default as historyoUZ3h5gLhMYMVQBqszG3bWyUrRIhX5fdWdh99fvdxjMMeta } from "/app/pages/employees/[employeeId]/history.vue?macro=true";
import { default as officesyUyUdAls3FQ642MjtdMZGejZYlClqq4hOtUoUy0V2h8Meta } from "/app/pages/employees/[employeeId]/offices.vue?macro=true";
import { default as benefits7zD0dG3Gyf_45Pm7ikVJtrV5pPuxFLEyDa5CTY0mZ0_45tYMeta } from "/app/pages/employees/[employeeId]/benefits.vue?macro=true";
import { default as addresseshgOGdmDLCB1ID6y9Q1EP1JobCCiv4vvDaTYL18qi69gMeta } from "/app/pages/employees/[employeeId]/addresses.vue?macro=true";
import { default as allergiesP5tN7ulhlxts0OODGFY1wOijR9YVdoJk5rS43uXkCsEMeta } from "/app/pages/employees/[employeeId]/allergies.vue?macro=true";
import { default as educationsPq6k_OmCXOt5iiEWwY7P9EJnk4HeO0ahfFAhMEfo6JIMeta } from "/app/pages/employees/[employeeId]/educations.vue?macro=true";
import { default as insurances8dHkuF3XvwSfQT5lt7SxQpoim2C8_tqmLmkZSbdNyrUMeta } from "/app/pages/employees/[employeeId]/insurances.vue?macro=true";
import { default as competencesbIov1T3Zc8SqHfKo8U6iF6fJi3HDs8n0mZv208eOD2EMeta } from "/app/pages/employees/[employeeId]/competences.vue?macro=true";
import { default as employmentsxWl_45wSGxitPfupwwv55hk5ZSDp3RswJ9BvCGjmc1QEsMeta } from "/app/pages/employees/[employeeId]/employments.vue?macro=true";
import { default as certificatesY_45yfgB38IMRtcYLz0XFWwwr3ZMGFo3N5z0UUG2G9cL8Meta } from "/app/pages/employees/[employeeId]/certificates.vue?macro=true";
import { default as indexiOcy8AnKO_Zxtauk1Qzg0D1tskaJP9Z3LpEQ2dbSIxQMeta } from "/app/pages/employees/[employeeId]/meetings/index.vue?macro=true";
import { default as okr_45objectivesAltqjenuVDc8kJa7tLTO8m_457_458qQiJzTTpJ86BeRMGkMeta } from "/app/pages/employees/[employeeId]/okr-objectives.vue?macro=true";
import { default as social_45handlestAe3LC_zzjZSMwga7sgLWlJB159xCKUWWBaICzvtJW0Meta } from "/app/pages/employees/[employeeId]/social-handles.vue?macro=true";
import { default as _91employeeId_93Yzt3PD62xG33jxbxek3FUuIrMGz2Rm3Vo6esXpg7bgwMeta } from "/app/pages/employees/[employeeId].vue?macro=true";
import { default as custom_45fieldsqiHExghNAp2uicFavACQivEPrCUCuonXCpJljvuZRXMMeta } from "/app/pages/organisations/settings/custom-fields.vue?macro=true";
import { default as settingsZ59azuCrKghCmLeaC9OEEfyfwRHmypL8T3WkMkvs3cIMeta } from "/app/pages/organisations/settings.vue?macro=true";
import { default as indexsV_45A8Rc92lz1gIhC19bc9wgPXrQoqvo4jTBBFnBVIiQMeta } from "/app/pages/employee-meetings/index.vue?macro=true";
import { default as _91_91powerGridId_93_93M7utOVv2RY9MHYIrwrua20RAwd4hN_fHES72_TdQGlEMeta } from "/app/pages/news/list/[[powerGridId]].vue?macro=true";
import { default as approvalLMdRmdqDPgwrJ19JuPjMJHUcRiPnT0b_45zNbPVzs2GewMeta } from "/app/pages/employees/absence/approval.vue?macro=true";
import { default as balancesDikbHpB17PeDQYjxF2ZBoEcpK1mdKRjnf_45MzaREejdIMeta } from "/app/pages/employees/absence/balances.vue?macro=true";
import { default as calendarfieZikcW_mEQIk2q0SMifwr4gyck3QSP1klf52ngukAMeta } from "/app/pages/employees/absence/calendar.vue?macro=true";
import { default as _91_91powerGridId_93_93y3sE07lLYmQbaeLLdi8Da5WMNnT1pJCkwczzsnF3ENYMeta } from "/app/pages/ideas/list/[[powerGridId]].vue?macro=true";
import { default as _91_91powerGridId_93_936HkfbC5H_q_XO0Czc8RtIA7vewwasgmyhJVMcTcSCssMeta } from "/app/pages/leads/list/[[powerGridId]].vue?macro=true";
import { default as _91_91powerGridId_93_93_jQQDcTxQNVVojrK4UzYMkV0EW_45Yxe_45pdXcx2nDTCscMeta } from "/app/pages/links/list/[[powerGridId]].vue?macro=true";
import { default as lead_45sourceszlJh_45dSLNB194HdnNxjrVZYzmV_nBfC5R3LniEMdCEQMeta } from "/app/pages/sales/reports/lead-sources.vue?macro=true";
import { default as lead_45successhgy3GNUx4iUArAxwzEY_7dpNT1M6FXcpZ2H_IQwVlToMeta } from "/app/pages/sales/reports/lead-success.vue?macro=true";
import { default as lead_45productsxQpA7ibl3_45HGk3zEGaAlb7uq2VJ66PUjYDHCdF0Exb8Meta } from "/app/pages/sales/reports/lead-products.vue?macro=true";
import { default as _91_91powerGridId_93_93HZ_456d4BQZhrr_45LgUj7yISMWdgWeQxSZ8Fwbk_45V_lc_QMeta } from "/app/pages/accounts/list/[[powerGridId]].vue?macro=true";
import { default as _91_91powerGridId_93_93IJ74JBSRuCUB_B0u8KY3I7tAVelvLSZjQOfL237hihsMeta } from "/app/pages/contacts/list/[[powerGridId]].vue?macro=true";
import { default as _91_91powerGridId_93_93tBBLN4ZrQ3vj6RTRCZ97iE_45bKIoCVIZLt_CnlrtP1wsMeta } from "/app/pages/products/list/[[powerGridId]].vue?macro=true";
import { default as _91_91powerGridId_93_93kFACEl57Ax6hD7ifrpNSdUj8adUIE18HQCWtS22geDMMeta } from "/app/pages/employees/list/[[powerGridId]].vue?macro=true";
import { default as filesc5eyegL5TrBAwlj2shK2GVBV0a9gf2PwmhrqUqaA_kwMeta } from "/app/pages/organisations/[organisationId]/files.vue?macro=true";
import { default as teams2w5zk_45PfpetKgPHOGhfqdgo_s_o5GprOiCtJfwbPrPMMeta } from "/app/pages/organisations/[organisationId]/teams.vue?macro=true";
import { default as familyPJmYgah0MO4osKbzx7EMPykyaL_452rjQnoWHxtzf5jXIMeta } from "/app/pages/organisations/[organisationId]/family.vue?macro=true";
import { default as absenceNfmCYdtFujw5JBA1wyzkm97I0MAc_458onSvUqU7bRGtoMeta } from "/app/pages/organisations/[organisationId]/absence.vue?macro=true";
import { default as details3alvXd63GZBHy679T5vwGbldrLEnE2S5_n7NbmTybAMMeta } from "/app/pages/organisations/[organisationId]/details.vue?macro=true";
import { default as devicesjS_45vMb3pbrhahNsZ7XTBJaGLN6Fo3SLsBitnOjDfkoYMeta } from "/app/pages/organisations/[organisationId]/devices.vue?macro=true";
import { default as historyZ9Fixoc6nUDdvqf_7TetGt8nzylwJi8r0SXnYSJWdsoMeta } from "/app/pages/organisations/[organisationId]/history.vue?macro=true";
import { default as officescz18rJpSTnNbdrp6W6sb6C2VPOZOXs9_45haO__45ec27OYMeta } from "/app/pages/organisations/[organisationId]/offices.vue?macro=true";
import { default as benefitsUHpxeIfDS_eez9EtvTcjqXNd4NFBjz9rdM9TCgQt_45AkMeta } from "/app/pages/organisations/[organisationId]/benefits.vue?macro=true";
import { default as addressesZzozj5NEOP2wYhnymIo3ruNDQYo2z_tnvVHErIZJ4QYMeta } from "/app/pages/organisations/[organisationId]/addresses.vue?macro=true";
import { default as allergiesNsLRJ8yBlkGlrBc_45WIlXV_45HijEmdlHMBzN2w41diO5YMeta } from "/app/pages/organisations/[organisationId]/allergies.vue?macro=true";
import { default as employeesNT9WQxoKnNYUIX1wjxMY3n1ol_455D3CNCFW4ydVlCKBoMeta } from "/app/pages/organisations/[organisationId]/employees.vue?macro=true";
import { default as numberingP_h04mGMSSaqaW7whUZA_45mZ5EN84KZMHzTtdqZpb1oQMeta } from "/app/pages/organisations/[organisationId]/numbering.vue?macro=true";
import { default as educationsyXGpVrkBIIhMP9hp8l20sVDTnumheTtZFuGFqrfMiA8Meta } from "/app/pages/organisations/[organisationId]/educations.vue?macro=true";
import { default as insurancesy2c0KoszR8nbLvV75CRvAPKC0RPsgJdkQsrZ04oGrLkMeta } from "/app/pages/organisations/[organisationId]/insurances.vue?macro=true";
import { default as competences_45ZZYJXbpu7gpICKFwYDiTT_45JN22vlLSDop6Lbw3pm9MMeta } from "/app/pages/organisations/[organisationId]/competences.vue?macro=true";
import { default as departmentslBHikQ59Oh84bsExHB0AZOM_GjJmMC14mZq4BJWgw_45AMeta } from "/app/pages/organisations/[organisationId]/departments.vue?macro=true";
import { default as certificatesmDiTOJW5oSEIr6RngCY5pCJJgEF1TRrUyf8Iu80t_loMeta } from "/app/pages/organisations/[organisationId]/certificates.vue?macro=true";
import { default as competence_45typess4YdIdyUypLkQA8tEgxM2UAVf_KyLOiKRhNTl1pzVF8Meta } from "/app/pages/organisations/[organisationId]/competence-types.vue?macro=true";
import { default as _91organisationId_93Pmzc6S6K2qxDWIxlOjjGqPAjRIS5wRYiUJ2pVMijAtQMeta } from "/app/pages/organisations/[organisationId].vue?macro=true";
import { default as lead_45competitors0ilYEHC4_Vm6F3A86idTCbF9ZgtyVsITrPOOoNxEOpgMeta } from "/app/pages/sales/reports/lead-competitors.vue?macro=true";
import { default as _91_91powerGridId_93_930vVdadQbGaJpjn1z32t3O9Joj5UB0OqbswYs1_eMuHwMeta } from "/app/pages/organisations/list/[[powerGridId]].vue?macro=true";
import { default as todoj9FV0gM8YoJ_sjWfQSOLNS4v3BUygwhF1y1IJ_CSWjUMeta } from "/app/pages/account-meetings/[accountMeetingId]/todo.vue?macro=true";
import { default as filesqs9MICPdviwp4A6IwelqAF5VcafshBFSMtJhgFNGr18Meta } from "/app/pages/account-meetings/[accountMeetingId]/files.vue?macro=true";
import { default as indexcZ9C9h7RR0bUseLyzx1g_45c2J_45ngH2BVSlDovwK33o5gMeta } from "/app/pages/account-meetings/[accountMeetingId]/index.vue?macro=true";
import { default as historyOHDZ42qLL_gHCC9YnFd9BjdMi500CwvKm8qxfbY7c4gMeta } from "/app/pages/account-meetings/[accountMeetingId]/history.vue?macro=true";
import { default as _91accountMeetingId_93MoozcGtoPf_B1pp602N22TmSboxiIBFNeXmotg2EtoQMeta } from "/app/pages/account-meetings/[accountMeetingId].vue?macro=true";
import { default as _91_91powerGridId_93_93TtAo0MQFE2VuB2tpugus4XYUvI5CpTrhdxBbGN6vhi0Meta } from "/app/pages/account-meetings/list/[[powerGridId]].vue?macro=true";
import { default as indexzZCWx1s3wFA9_aadb8FgBN45FGd0n_w73qSiEw05g7wMeta } from "/app/pages/employee-meetings/[employeeMeetingId]/index.vue?macro=true";
import { default as _91employeeMeetingId_937AFaF_45W_1GF2ke4n1K_457yXYXgjiC8RIy1Ix5uu2lNiEMeta } from "/app/pages/employee-meetings/[employeeMeetingId].vue?macro=true";
export default [
  {
    name: "me",
    path: "/me",
    component: () => import("/app/pages/me.vue")
  },
  {
    name: adminHy0fZKw3Hs3Ilpe9PIV9_Obs0tEAeICxhGLuXJq_45_SMMeta?.name,
    path: "/admin",
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin",
    path: "",
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-modules",
    path: "modules",
    meta: modules4OO_5zVxEo3uyTz7yV2jvqmdzVcMrUoZwqQ0IgzeCEEMeta || {},
    component: () => import("/app/pages/admin/modules.vue")
  },
  {
    name: "admin-instance",
    path: "instance",
    component: () => import("/app/pages/admin/instance.vue")
  },
  {
    name: "admin-developer-entities",
    path: "developer/entities",
    meta: entitiesxMIr64RgoshYzJ84lewiSajkG3vLdw7xgQdppb9_45bUoMeta || {},
    component: () => import("/app/pages/admin/developer/entities.vue")
  },
  {
    name: "admin-developer-broadcast",
    path: "developer/broadcast",
    component: () => import("/app/pages/admin/developer/broadcast.vue")
  },
  {
    name: "admin-developer-languages",
    path: "developer/languages",
    meta: languageshnEUlgLPv3PyVOQtSwOAei3Xu6bM4aQKIxWAGqN5XWgMeta || {},
    component: () => import("/app/pages/admin/developer/languages.vue")
  },
  {
    name: "admin-developer-provisioning",
    path: "developer/provisioning",
    component: () => import("/app/pages/admin/developer/provisioning.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "hr",
    path: "/hr",
    component: () => import("/app/pages/hr/index.vue")
  },
  {
    name: "news-new",
    path: "/news/new",
    component: () => import("/app/pages/news/new.vue")
  },
  {
    name: "crm",
    path: "/crm",
    component: () => import("/app/pages/crm/index.vue")
  },
  {
    name: "leads-new",
    path: "/leads/new",
    component: () => import("/app/pages/leads/new.vue")
  },
  {
    name: "error-test",
    path: "/error-test",
    component: () => import("/app/pages/error-test.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/pages/news/index.vue")
  },
  {
    name: "auth-client",
    path: "/auth/client",
    component: () => import("/app/pages/auth/client.vue")
  },
  {
    name: "cm-settings",
    path: "/cm-settings",
    component: () => import("/app/pages/cm-settings.vue")
  },
  {
    name: "hr-settings",
    path: "/hr/settings",
    component: () => import("/app/pages/hr/settings.vue"),
    children: [
  {
    name: "hr-settings-employees-workdays",
    path: "employees/workdays",
    component: () => import("/app/pages/hr/settings/employees/workdays.vue")
  },
  {
    name: "hr-settings-employees-positions",
    path: "employees/positions",
    component: () => import("/app/pages/hr/settings/employees/positions.vue")
  },
  {
    name: "hr-settings-employees-size-feet",
    path: "employees/size-feet",
    component: () => import("/app/pages/hr/settings/employees/size-feet.vue")
  },
  {
    name: "hr-settings-employees-size-legs",
    path: "employees/size-legs",
    component: () => import("/app/pages/hr/settings/employees/size-legs.vue")
  },
  {
    name: "hr-settings-employees-size-torso",
    path: "employees/size-torso",
    component: () => import("/app/pages/hr/settings/employees/size-torso.vue")
  },
  {
    name: "hr-settings-employees-size-waist",
    path: "employees/size-waist",
    component: () => import("/app/pages/hr/settings/employees/size-waist.vue")
  },
  {
    name: "hr-settings-employees-allergy-types",
    path: "employees/allergy-types",
    component: () => import("/app/pages/hr/settings/employees/allergy-types.vue")
  },
  {
    name: "hr-settings-employees-custom-fields",
    path: "employees/custom-fields",
    component: () => import("/app/pages/hr/settings/employees/custom-fields.vue")
  },
  {
    name: "hr-settings-employees-competence-types",
    path: "employees/competence-types",
    component: () => import("/app/pages/hr/settings/employees/competence-types.vue")
  },
  {
    name: "hr-settings-employees-employment-types",
    path: "employees/employment-types",
    component: () => import("/app/pages/hr/settings/employees/employment-types.vue")
  },
  {
    name: "hr-settings-employees-certificate-types",
    path: "employees/certificate-types",
    component: () => import("/app/pages/hr/settings/employees/certificate-types.vue")
  },
  {
    name: "hr-settings-employees-employment-wage-types",
    path: "employees/employment-wage-types",
    component: () => import("/app/pages/hr/settings/employees/employment-wage-types.vue")
  },
  {
    name: "hr-settings-employees-family-relation-types",
    path: "employees/family-relation-types",
    component: () => import("/app/pages/hr/settings/employees/family-relation-types.vue")
  }
]
  },
  {
    name: "ideas",
    path: "/ideas",
    component: () => import("/app/pages/ideas/index.vue")
  },
  {
    name: "leads",
    path: "/leads",
    component: () => import("/app/pages/leads/index.vue")
  },
  {
    name: "links",
    path: "/links",
    component: () => import("/app/pages/links/index.vue")
  },
  {
    name: "login-error",
    path: "/login-error",
    meta: login_45errorriBE_ZmPcHv0oYWXLNF4pk28__tWa1C4xUGGBWjQc_45kMeta || {},
    component: () => import("/app/pages/login-error.vue")
  },
  {
    name: "news-drafts",
    path: "/news/drafts",
    component: () => import("/app/pages/news/drafts.vue")
  },
  {
    name: "release-log",
    path: "/release-log",
    component: () => import("/app/pages/release-log.vue")
  },
  {
    name: "accounts-new",
    path: "/accounts/new",
    component: () => import("/app/pages/accounts/new.vue")
  },
  {
    name: "contacts-new",
    path: "/contacts/new",
    component: () => import("/app/pages/contacts/new.vue")
  },
  {
    name: "crm-settings",
    path: "/crm/settings",
    component: () => import("/app/pages/crm/settings.vue"),
    children: [
  {
    name: "crm-settings-gdpr-settings",
    path: "gdpr/settings",
    meta: settingsb2_45hqlsseoWF_45uiqzblOfKtcJAgrZyJmuxXOutEaTmcMeta || {},
    component: () => import("/app/pages/crm/settings/gdpr/settings.vue")
  },
  {
    name: "crm-settings-accounts-types",
    path: "accounts/types",
    component: () => import("/app/pages/crm/settings/accounts/types.vue")
  },
  {
    name: "crm-settings-campaign-monitor",
    path: "campaign-monitor",
    meta: campaign_45monitorb58jKOnhgmsIm5ewTzgaYFb2iYpvgC39SI1mOqxEAYsMeta || {},
    component: () => import("/app/pages/crm/settings/campaign-monitor.vue")
  },
  {
    name: "crm-settings-contacts-positions",
    path: "contacts/positions",
    component: () => import("/app/pages/crm/settings/contacts/positions.vue")
  },
  {
    name: "crm-settings-gdpr-consent-types",
    path: "gdpr/consent-types",
    component: () => import("/app/pages/crm/settings/gdpr/consent-types.vue")
  },
  {
    name: "crm-settings-accounts-industries",
    path: "accounts/industries",
    component: () => import("/app/pages/crm/settings/accounts/industries.vue")
  },
  {
    name: "crm-settings-accounts-custom-fields",
    path: "accounts/custom-fields",
    component: () => import("/app/pages/crm/settings/accounts/custom-fields.vue")
  },
  {
    name: "crm-settings-contacts-custom-fields",
    path: "contacts/custom-fields",
    component: () => import("/app/pages/crm/settings/contacts/custom-fields.vue")
  },
  {
    name: "crm-settings-account-meetings-custom-fields",
    path: "account-meetings/custom-fields",
    component: () => import("/app/pages/crm/settings/account-meetings/custom-fields.vue")
  }
]
  },
  {
    name: "order-module",
    path: "/order-module",
    component: () => import("/app/pages/order-module.vue")
  },
  {
    name: "products-new",
    path: "/products/new",
    component: () => import("/app/pages/products/new.vue")
  },
  {
    name: "server-error",
    path: "/server-error",
    meta: server_45error_TFbMd4gdcl5Vg1kc71HhO0IfM8aNnKdYdZB2EiH7xsMeta || {},
    component: () => import("/app/pages/server-error.vue")
  },
  {
    name: "setup-client",
    path: "/setup/client",
    meta: client7zkWdzYTeeL7M66NVkK3fz4lw4w5RcDZJ5JcqFNlN4IMeta || {},
    component: () => import("/app/pages/setup/client.vue")
  },
  {
    name: "slides",
    path: "/slides",
    meta: indexKG6JiwNhEbpx3QdDMzByQaNZBJsM8Rxazy9ksmWDRGEMeta || {},
    component: () => import("/app/pages/slides/index.vue")
  },
  {
    name: "workflow-poc",
    path: "/workflow-poc",
    component: () => import("/app/pages/workflow-poc.vue")
  },
  {
    name: "employees-new",
    path: "/employees/new",
    meta: newI89WaWdNFaTwy9nuTtmkYTdwTLIruLXdDZcQtBMsueYMeta || {},
    component: () => import("/app/pages/employees/new.vue")
  },
  {
    name: settingsCqsa8eu78kVbg6S3RwVNvlHXGDO7V3FK38rZz4rUZjsMeta?.name,
    path: "/news/settings",
    component: () => import("/app/pages/news/settings.vue"),
    children: [
  {
    name: "news-settings",
    path: "",
    component: () => import("/app/pages/news/settings/index.vue")
  },
  {
    name: "news-settings-custom-fields",
    path: "custom-fields",
    component: () => import("/app/pages/news/settings/custom-fields.vue")
  }
]
  },
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/app/pages/accounts/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/contacts/index.vue")
  },
  {
    name: "ideas-ideaId",
    path: "/ideas/:ideaId()",
    component: () => import("/app/pages/ideas/[ideaId].vue")
  },
  {
    name: "ideas-my-ideas",
    path: "/ideas/my-ideas",
    meta: my_45ideasx5mGwdidSumsw9Jd3pgQ430svE6qETn9EQSepBvaNZIMeta || {},
    component: () => import("/app/pages/ideas/my-ideas.vue")
  },
  {
    name: "leads-leadId",
    path: "/leads/:leadId()",
    component: () => import("/app/pages/leads/[leadId].vue"),
    children: [
  {
    name: "leads-leadId-files",
    path: "files",
    component: () => import("/app/pages/leads/[leadId]/files.vue")
  },
  {
    name: "leads-leadId-details",
    path: "details",
    meta: detailsLNAB7bmr34fHkYBGObNhReTMlwvTU6yQ_45NS9Vl33wbEMeta || {},
    alias: ["/leads/:leadId"],
    component: () => import("/app/pages/leads/[leadId]/details.vue")
  },
  {
    name: "leads-leadId-history",
    path: "history",
    meta: historyNUjzYhhEHWeOK_FZ9qjqJxpKvfiThEIxrJvipCwKkkMMeta || {},
    component: () => import("/app/pages/leads/[leadId]/history.vue")
  },
  {
    name: "leads-leadId-activities",
    path: "activities",
    component: () => import("/app/pages/leads/[leadId]/activities.vue")
  }
]
  },
  {
    name: "links-settings",
    path: "/links/settings",
    component: () => import("/app/pages/links/settings.vue"),
    children: [
  {
    name: "links-settings-categories",
    path: "categories",
    component: () => import("/app/pages/links/settings/categories.vue")
  },
  {
    name: "links-settings-custom-fields",
    path: "custom-fields",
    component: () => import("/app/pages/links/settings/custom-fields.vue")
  }
]
  },
  {
    name: "news-id-edit",
    path: "/news/:id()/edit",
    component: () => import("/app/pages/news/[id]/edit.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/app/pages/products/index.vue")
  },
  {
    name: "rich-text-demo",
    path: "/rich-text-demo",
    component: () => import("/app/pages/rich-text-demo.vue")
  },
  {
    name: "sales-settings",
    path: "/sales/settings",
    component: () => import("/app/pages/sales/settings.vue"),
    children: [
  {
    name: "sales-settings-leads-ratings",
    path: "leads/ratings",
    component: () => import("/app/pages/sales/settings/leads/ratings.vue")
  },
  {
    name: "sales-settings-leads-sources",
    path: "leads/sources",
    component: () => import("/app/pages/sales/settings/leads/sources.vue")
  },
  {
    name: "sales-settings-slides-settings",
    path: "slides/settings",
    component: () => import("/app/pages/sales/settings/slides/settings.vue")
  },
  {
    name: "sales-settings-slides-categories",
    path: "slides/categories",
    component: () => import("/app/pages/sales/settings/slides/categories.vue")
  },
  {
    name: "sales-settings-slides-topic-types",
    path: "slides/topic-types",
    component: () => import("/app/pages/sales/settings/slides/topic-types.vue")
  },
  {
    name: "sales-settings-leads-custom-fields",
    path: "leads/custom-fields",
    component: () => import("/app/pages/sales/settings/leads/custom-fields.vue")
  }
]
  },
  {
    name: "employees",
    path: "/employees",
    component: () => import("/app/pages/employees/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/app/pages/news/[id]/index.vue")
  },
  {
    name: "finance-settings",
    path: "/finance/settings",
    component: () => import("/app/pages/finance/settings.vue"),
    children: [
  {
    name: "finance-settings-products-brands",
    path: "products/brands",
    component: () => import("/app/pages/finance/settings/products/brands.vue")
  },
  {
    name: "finance-settings-products-metrics",
    path: "products/metrics",
    component: () => import("/app/pages/finance/settings/products/metrics.vue")
  },
  {
    name: "finance-settings-products-categories",
    path: "products/categories",
    component: () => import("/app/pages/finance/settings/products/categories.vue")
  },
  {
    name: "finance-settings-products-custom-fields",
    path: "products/custom-fields",
    component: () => import("/app/pages/finance/settings/products/custom-fields.vue")
  }
]
  },
  {
    name: "organisations-new",
    path: "/organisations/new",
    component: () => import("/app/pages/organisations/new.vue")
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/app/pages/organisations/index.vue")
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    component: () => import("/app/pages/projects/[id]/index.vue")
  },
  {
    name: "account-meetings-new",
    path: "/account-meetings/new",
    component: () => import("/app/pages/account-meetings/new.vue")
  },
  {
    name: "accounts-accountId",
    path: "/accounts/:accountId()",
    component: () => import("/app/pages/accounts/[accountId].vue"),
    children: [
  {
    name: "accounts-accountId-files",
    path: "files",
    component: () => import("/app/pages/accounts/[accountId]/files.vue")
  },
  {
    name: "accounts-accountId-leads",
    path: "leads",
    component: () => import("/app/pages/accounts/[accountId]/leads.vue")
  },
  {
    name: "accounts-accountId-details",
    path: "details",
    meta: details_45YS1zKCrhgaJUCAXtWQv1tLw2OGlKjxufR7EsjUf1LgMeta || {},
    alias: ["/accounts/:accountId"],
    component: () => import("/app/pages/accounts/[accountId]/details.vue")
  },
  {
    name: "accounts-accountId-history",
    path: "history",
    meta: historyHXvydfo6x4Xn0cs6wf9fJJoCEy6tmMEw_45HDnhhjyc40Meta || {},
    component: () => import("/app/pages/accounts/[accountId]/history.vue")
  },
  {
    name: "accounts-accountId-contacts",
    path: "contacts",
    component: () => import("/app/pages/accounts/[accountId]/contacts.vue")
  },
  {
    name: "accounts-accountId-meetings",
    path: "meetings",
    component: () => import("/app/pages/accounts/[accountId]/meetings.vue")
  },
  {
    name: "accounts-accountId-addresses",
    path: "addresses",
    component: () => import("/app/pages/accounts/[accountId]/addresses.vue")
  },
  {
    name: "accounts-accountId-activities",
    path: "activities",
    component: () => import("/app/pages/accounts/[accountId]/activities.vue")
  }
]
  },
  {
    name: "contacts-contactId",
    path: "/contacts/:contactId()",
    component: () => import("/app/pages/contacts/[contactId].vue"),
    children: [
  {
    name: "contacts-contactId-files",
    path: "files",
    component: () => import("/app/pages/contacts/[contactId]/files.vue")
  },
  {
    name: "contacts-contactId-leads",
    path: "leads",
    component: () => import("/app/pages/contacts/[contactId]/leads.vue")
  },
  {
    name: "contacts-contactId-details",
    path: "details",
    meta: detailsoYlwwGFyddw_DUUXF__45KcpMVqqPsPEsth3_P_xNTiXoMeta || {},
    alias: ["/contacts/:contactId"],
    component: () => import("/app/pages/contacts/[contactId]/details.vue")
  },
  {
    name: "contacts-contactId-history",
    path: "history",
    meta: historyQ5iGUlgpf3Oc0xHgc3VsF9KhebVv536Z4I2adnMA0BoMeta || {},
    component: () => import("/app/pages/contacts/[contactId]/history.vue")
  },
  {
    name: "contacts-contactId-consents",
    path: "consents",
    meta: consentsts8KWVQIcluIxUIdPlmk8ud2eP5ICXuh6QEsmWcn_45aAMeta || {},
    component: () => import("/app/pages/contacts/[contactId]/consents.vue")
  },
  {
    name: "contacts-contactId-meetings",
    path: "meetings",
    component: () => import("/app/pages/contacts/[contactId]/meetings.vue")
  },
  {
    name: "contacts-contactId-activities",
    path: "activities",
    component: () => import("/app/pages/contacts/[contactId]/activities.vue")
  },
  {
    name: "contacts-contactId-social-handles",
    path: "social-handles",
    component: () => import("/app/pages/contacts/[contactId]/social-handles.vue")
  }
]
  },
  {
    name: "no-clients-available",
    path: "/no-clients-available",
    meta: no_45clients_45availabledlhjoj8TCQTFVkjiiQauy2nRuL1w_StIQM6E_45YOO9DsMeta || {},
    component: () => import("/app/pages/no-clients-available.vue")
  },
  {
    name: "products-productId",
    path: "/products/:productId()",
    component: () => import("/app/pages/products/[productId].vue"),
    children: [
  {
    name: "products-productId-files",
    path: "files",
    component: () => import("/app/pages/products/[productId]/files.vue")
  },
  {
    name: "products-productId-details",
    path: "details",
    meta: detailsaA4m6WWOZ5uSRnwFiWfFJZVThxOhy_45Lt6lvjUsSA4_45MMeta || {},
    alias: ["/products/:productId"],
    component: () => import("/app/pages/products/[productId]/details.vue")
  },
  {
    name: "products-productId-history",
    path: "history",
    meta: historyeXFlL5m2zfstrRbShUhxXqT6cMmu_TDvoR5HlOD_45WYsMeta || {},
    component: () => import("/app/pages/products/[productId]/history.vue")
  }
]
  },
  {
    name: "account-meetings",
    path: "/account-meetings",
    component: () => import("/app/pages/account-meetings/index.vue")
  },
  {
    name: "employees-employeeId",
    path: "/employees/:employeeId()",
    meta: _91employeeId_93Yzt3PD62xG33jxbxek3FUuIrMGz2Rm3Vo6esXpg7bgwMeta || {},
    component: () => import("/app/pages/employees/[employeeId].vue"),
    children: [
  {
    name: "employees-employeeId-files",
    path: "files",
    component: () => import("/app/pages/employees/[employeeId]/files.vue")
  },
  {
    name: "employees-employeeId-leads",
    path: "leads",
    component: () => import("/app/pages/employees/[employeeId]/leads.vue")
  },
  {
    name: "employees-employeeId-teams",
    path: "teams",
    meta: teamszFprV9NXCTaq8BLkzniqBBfUWu3Iwz4jJLah4qN3w18Meta || {},
    component: () => import("/app/pages/employees/[employeeId]/teams.vue")
  },
  {
    name: "employees-employeeId-family",
    path: "family",
    meta: familyFjQRuPxHgfL1kqsCuQSkL8iDY5R5WJ9R2iD0PacbmXUMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/family.vue")
  },
  {
    name: "employees-employeeId-absence",
    path: "absence",
    meta: absencesPRGqKjLdFrJnRrtqFVuaZEbupRdAeomeMolpq1ls9EMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/absence.vue")
  },
  {
    name: "employees-employeeId-details",
    path: "details",
    meta: detailsmpNomdI_j4usEt0lmmOtpQAQQ1tXn3s_U9TkRRcpYb0Meta || {},
    alias: ["/employees/:employeeId"],
    component: () => import("/app/pages/employees/[employeeId]/details.vue")
  },
  {
    name: "employees-employeeId-devices",
    path: "devices",
    meta: devicesYQsJaCkobhjvy_qvdmC2cZj5_tA11ASR23vgWxvVnrwMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/devices.vue")
  },
  {
    name: "employees-employeeId-history",
    path: "history",
    meta: historyoUZ3h5gLhMYMVQBqszG3bWyUrRIhX5fdWdh99fvdxjMMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/history.vue")
  },
  {
    name: "employees-employeeId-offices",
    path: "offices",
    meta: officesyUyUdAls3FQ642MjtdMZGejZYlClqq4hOtUoUy0V2h8Meta || {},
    component: () => import("/app/pages/employees/[employeeId]/offices.vue")
  },
  {
    name: "employees-employeeId-benefits",
    path: "benefits",
    meta: benefits7zD0dG3Gyf_45Pm7ikVJtrV5pPuxFLEyDa5CTY0mZ0_45tYMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/benefits.vue")
  },
  {
    name: "employees-employeeId-addresses",
    path: "addresses",
    meta: addresseshgOGdmDLCB1ID6y9Q1EP1JobCCiv4vvDaTYL18qi69gMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/addresses.vue")
  },
  {
    name: "employees-employeeId-allergies",
    path: "allergies",
    meta: allergiesP5tN7ulhlxts0OODGFY1wOijR9YVdoJk5rS43uXkCsEMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/allergies.vue")
  },
  {
    name: "employees-employeeId-educations",
    path: "educations",
    meta: educationsPq6k_OmCXOt5iiEWwY7P9EJnk4HeO0ahfFAhMEfo6JIMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/educations.vue")
  },
  {
    name: "employees-employeeId-insurances",
    path: "insurances",
    meta: insurances8dHkuF3XvwSfQT5lt7SxQpoim2C8_tqmLmkZSbdNyrUMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/insurances.vue")
  },
  {
    name: "employees-employeeId-competences",
    path: "competences",
    meta: competencesbIov1T3Zc8SqHfKo8U6iF6fJi3HDs8n0mZv208eOD2EMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/competences.vue")
  },
  {
    name: "employees-employeeId-employments",
    path: "employments",
    meta: employmentsxWl_45wSGxitPfupwwv55hk5ZSDp3RswJ9BvCGjmc1QEsMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/employments.vue")
  },
  {
    name: "employees-employeeId-certificates",
    path: "certificates",
    meta: certificatesY_45yfgB38IMRtcYLz0XFWwwr3ZMGFo3N5z0UUG2G9cL8Meta || {},
    component: () => import("/app/pages/employees/[employeeId]/certificates.vue")
  },
  {
    name: "employees-employeeId-meetings",
    path: "meetings",
    meta: indexiOcy8AnKO_Zxtauk1Qzg0D1tskaJP9Z3LpEQ2dbSIxQMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/meetings/index.vue")
  },
  {
    name: "employees-employeeId-okr-objectives",
    path: "okr-objectives",
    meta: okr_45objectivesAltqjenuVDc8kJa7tLTO8m_457_458qQiJzTTpJ86BeRMGkMeta || {},
    component: () => import("/app/pages/employees/[employeeId]/okr-objectives.vue")
  },
  {
    name: "employees-employeeId-social-handles",
    path: "social-handles",
    component: () => import("/app/pages/employees/[employeeId]/social-handles.vue")
  }
]
  },
  {
    name: "organisations-settings",
    path: "/organisations/settings",
    component: () => import("/app/pages/organisations/settings.vue"),
    children: [
  {
    name: "organisations-settings-custom-fields",
    path: "custom-fields",
    component: () => import("/app/pages/organisations/settings/custom-fields.vue")
  }
]
  },
  {
    name: "employee-meetings",
    path: "/employee-meetings",
    component: () => import("/app/pages/employee-meetings/index.vue")
  },
  {
    name: "news-list-powerGridId",
    path: "/news/list/:powerGridId?",
    component: () => import("/app/pages/news/list/[[powerGridId]].vue")
  },
  {
    name: "employees-absence-approval",
    path: "/employees/absence/approval",
    meta: approvalLMdRmdqDPgwrJ19JuPjMJHUcRiPnT0b_45zNbPVzs2GewMeta || {},
    component: () => import("/app/pages/employees/absence/approval.vue")
  },
  {
    name: "employees-absence-balances",
    path: "/employees/absence/balances",
    meta: balancesDikbHpB17PeDQYjxF2ZBoEcpK1mdKRjnf_45MzaREejdIMeta || {},
    component: () => import("/app/pages/employees/absence/balances.vue")
  },
  {
    name: "employees-absence-calendar",
    path: "/employees/absence/calendar",
    component: () => import("/app/pages/employees/absence/calendar.vue")
  },
  {
    name: "ideas-list-powerGridId",
    path: "/ideas/list/:powerGridId?",
    component: () => import("/app/pages/ideas/list/[[powerGridId]].vue")
  },
  {
    name: "leads-list-powerGridId",
    path: "/leads/list/:powerGridId?",
    component: () => import("/app/pages/leads/list/[[powerGridId]].vue")
  },
  {
    name: "links-list-powerGridId",
    path: "/links/list/:powerGridId?",
    component: () => import("/app/pages/links/list/[[powerGridId]].vue")
  },
  {
    name: "sales-reports-lead-sources",
    path: "/sales/reports/lead-sources",
    meta: lead_45sourceszlJh_45dSLNB194HdnNxjrVZYzmV_nBfC5R3LniEMdCEQMeta || {},
    component: () => import("/app/pages/sales/reports/lead-sources.vue")
  },
  {
    name: "sales-reports-lead-success",
    path: "/sales/reports/lead-success",
    meta: lead_45successhgy3GNUx4iUArAxwzEY_7dpNT1M6FXcpZ2H_IQwVlToMeta || {},
    component: () => import("/app/pages/sales/reports/lead-success.vue")
  },
  {
    name: "sales-reports-lead-products",
    path: "/sales/reports/lead-products",
    meta: lead_45productsxQpA7ibl3_45HGk3zEGaAlb7uq2VJ66PUjYDHCdF0Exb8Meta || {},
    component: () => import("/app/pages/sales/reports/lead-products.vue")
  },
  {
    name: "accounts-list-powerGridId",
    path: "/accounts/list/:powerGridId?",
    component: () => import("/app/pages/accounts/list/[[powerGridId]].vue")
  },
  {
    name: "contacts-list-powerGridId",
    path: "/contacts/list/:powerGridId?",
    component: () => import("/app/pages/contacts/list/[[powerGridId]].vue")
  },
  {
    name: "products-list-powerGridId",
    path: "/products/list/:powerGridId?",
    component: () => import("/app/pages/products/list/[[powerGridId]].vue")
  },
  {
    name: "employees-list-powerGridId",
    path: "/employees/list/:powerGridId?",
    component: () => import("/app/pages/employees/list/[[powerGridId]].vue")
  },
  {
    name: "organisations-organisationId",
    path: "/organisations/:organisationId()",
    meta: _91organisationId_93Pmzc6S6K2qxDWIxlOjjGqPAjRIS5wRYiUJ2pVMijAtQMeta || {},
    component: () => import("/app/pages/organisations/[organisationId].vue"),
    children: [
  {
    name: "organisations-organisationId-files",
    path: "files",
    component: () => import("/app/pages/organisations/[organisationId]/files.vue")
  },
  {
    name: "organisations-organisationId-teams",
    path: "teams",
    meta: teams2w5zk_45PfpetKgPHOGhfqdgo_s_o5GprOiCtJfwbPrPMMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/teams.vue")
  },
  {
    name: "organisations-organisationId-family",
    path: "family",
    meta: familyPJmYgah0MO4osKbzx7EMPykyaL_452rjQnoWHxtzf5jXIMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/family.vue")
  },
  {
    name: "organisations-organisationId-absence",
    path: "absence",
    meta: absenceNfmCYdtFujw5JBA1wyzkm97I0MAc_458onSvUqU7bRGtoMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/absence.vue")
  },
  {
    name: "organisations-organisationId-details",
    path: "details",
    meta: details3alvXd63GZBHy679T5vwGbldrLEnE2S5_n7NbmTybAMMeta || {},
    alias: ["/organisations/:organisationId"],
    component: () => import("/app/pages/organisations/[organisationId]/details.vue")
  },
  {
    name: "organisations-organisationId-devices",
    path: "devices",
    meta: devicesjS_45vMb3pbrhahNsZ7XTBJaGLN6Fo3SLsBitnOjDfkoYMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/devices.vue")
  },
  {
    name: "organisations-organisationId-history",
    path: "history",
    meta: historyZ9Fixoc6nUDdvqf_7TetGt8nzylwJi8r0SXnYSJWdsoMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/history.vue")
  },
  {
    name: "organisations-organisationId-offices",
    path: "offices",
    meta: officescz18rJpSTnNbdrp6W6sb6C2VPOZOXs9_45haO__45ec27OYMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/offices.vue")
  },
  {
    name: "organisations-organisationId-benefits",
    path: "benefits",
    meta: benefitsUHpxeIfDS_eez9EtvTcjqXNd4NFBjz9rdM9TCgQt_45AkMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/benefits.vue")
  },
  {
    name: "organisations-organisationId-addresses",
    path: "addresses",
    meta: addressesZzozj5NEOP2wYhnymIo3ruNDQYo2z_tnvVHErIZJ4QYMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/addresses.vue")
  },
  {
    name: "organisations-organisationId-allergies",
    path: "allergies",
    meta: allergiesNsLRJ8yBlkGlrBc_45WIlXV_45HijEmdlHMBzN2w41diO5YMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/allergies.vue")
  },
  {
    name: "organisations-organisationId-employees",
    path: "employees",
    meta: employeesNT9WQxoKnNYUIX1wjxMY3n1ol_455D3CNCFW4ydVlCKBoMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/employees.vue")
  },
  {
    name: "organisations-organisationId-numbering",
    path: "numbering",
    meta: numberingP_h04mGMSSaqaW7whUZA_45mZ5EN84KZMHzTtdqZpb1oQMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/numbering.vue")
  },
  {
    name: "organisations-organisationId-educations",
    path: "educations",
    meta: educationsyXGpVrkBIIhMP9hp8l20sVDTnumheTtZFuGFqrfMiA8Meta || {},
    component: () => import("/app/pages/organisations/[organisationId]/educations.vue")
  },
  {
    name: "organisations-organisationId-insurances",
    path: "insurances",
    meta: insurancesy2c0KoszR8nbLvV75CRvAPKC0RPsgJdkQsrZ04oGrLkMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/insurances.vue")
  },
  {
    name: "organisations-organisationId-competences",
    path: "competences",
    meta: competences_45ZZYJXbpu7gpICKFwYDiTT_45JN22vlLSDop6Lbw3pm9MMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/competences.vue")
  },
  {
    name: "organisations-organisationId-departments",
    path: "departments",
    meta: departmentslBHikQ59Oh84bsExHB0AZOM_GjJmMC14mZq4BJWgw_45AMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/departments.vue")
  },
  {
    name: "organisations-organisationId-certificates",
    path: "certificates",
    meta: certificatesmDiTOJW5oSEIr6RngCY5pCJJgEF1TRrUyf8Iu80t_loMeta || {},
    component: () => import("/app/pages/organisations/[organisationId]/certificates.vue")
  },
  {
    name: "organisations-organisationId-competence-types",
    path: "competence-types",
    component: () => import("/app/pages/organisations/[organisationId]/competence-types.vue")
  }
]
  },
  {
    name: "sales-reports-lead-competitors",
    path: "/sales/reports/lead-competitors",
    meta: lead_45competitors0ilYEHC4_Vm6F3A86idTCbF9ZgtyVsITrPOOoNxEOpgMeta || {},
    component: () => import("/app/pages/sales/reports/lead-competitors.vue")
  },
  {
    name: "organisations-list-powerGridId",
    path: "/organisations/list/:powerGridId?",
    component: () => import("/app/pages/organisations/list/[[powerGridId]].vue")
  },
  {
    name: _91accountMeetingId_93MoozcGtoPf_B1pp602N22TmSboxiIBFNeXmotg2EtoQMeta?.name,
    path: "/account-meetings/:accountMeetingId()",
    component: () => import("/app/pages/account-meetings/[accountMeetingId].vue"),
    children: [
  {
    name: "account-meetings-accountMeetingId-todo",
    path: "todo",
    component: () => import("/app/pages/account-meetings/[accountMeetingId]/todo.vue")
  },
  {
    name: "account-meetings-accountMeetingId-files",
    path: "files",
    component: () => import("/app/pages/account-meetings/[accountMeetingId]/files.vue")
  },
  {
    name: "account-meetings-accountMeetingId",
    path: "",
    meta: indexcZ9C9h7RR0bUseLyzx1g_45c2J_45ngH2BVSlDovwK33o5gMeta || {},
    component: () => import("/app/pages/account-meetings/[accountMeetingId]/index.vue")
  },
  {
    name: "account-meetings-accountMeetingId-history",
    path: "history",
    meta: historyOHDZ42qLL_gHCC9YnFd9BjdMi500CwvKm8qxfbY7c4gMeta || {},
    component: () => import("/app/pages/account-meetings/[accountMeetingId]/history.vue")
  }
]
  },
  {
    name: "account-meetings-list-powerGridId",
    path: "/account-meetings/list/:powerGridId?",
    component: () => import("/app/pages/account-meetings/list/[[powerGridId]].vue")
  },
  {
    name: _91employeeMeetingId_937AFaF_45W_1GF2ke4n1K_457yXYXgjiC8RIy1Ix5uu2lNiEMeta?.name,
    path: "/employee-meetings/:employeeMeetingId()",
    component: () => import("/app/pages/employee-meetings/[employeeMeetingId].vue"),
    children: [
  {
    name: "employee-meetings-employeeMeetingId",
    path: "",
    meta: indexzZCWx1s3wFA9_aadb8FgBN45FGd0n_w73qSiEw05g7wMeta || {},
    component: () => import("/app/pages/employee-meetings/[employeeMeetingId]/index.vue")
  }
]
  }
]