import { AxosModules, Query } from '~/types/axos-api';
export default defineNuxtRouteMiddleware(async (to, from) => {
	const { IS_TEST } = useEnvironment();
	if (IS_TEST) {
		return;
	}

	if (to.path === '/contacts' || to.path === '/contacts/') {
		return navigateTo('/contacts/list');
	}

	if (to.path === '/accounts' || to.path === '/accounts/') {
		return navigateTo('/accounts/list');
	}

	if (to.path === '/employees' || to.path === '/employees/') {
		return navigateTo('/employees/list');
	}

	if (to.path === '/account-meetings' || to.path === '/account-meetings/') {
		return navigateTo('/account-meetings/list');
	}

	if (to.path === '/leads' || to.path === '/leads/') {
		return navigateTo('/leads/list');
	}

	if (to.path === '/organisations' || to.path === '/organisations/') {
		return navigateTo('/organisations/list');
	}

	if (to.path === '/products' || to.path === '/products/') {
		return navigateTo('/products/list');
	}

	if (to.path === '/links' || to.path === '/links/') {
		return navigateTo('/links/list');
	}

	if (to.path === '/news' || to.path === '/news/') {
		return navigateTo('/news/list');
	}
});
