<template>
	<!-- Main sidebar with icons -->
	<nav
		class="z-20 shrink-0 border-r bg-[var(--app-sidemenu-bg)]"
		:class="{
			'w-24 p-2': showLabels,
			'w-16 py-1.5': !showLabels,
		}"
	>
		<ul
			role="list"
			class="flex h-full flex-col gap-y-1"
			:class="{
				'items-left': showLabels,
				'items-center': !showLabels,
			}"
		>
			<!-- Organisation-->
			<li
				v-if="
					hasModule(AxosModules.Organisation) &&
					(isAdmin || isEmployeeManager)
				"
			>
				<NuxtLink
					to="/organisations"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/organisations'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_ORGANISATIONS"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Organisation.Entity.Name') }}
					</span>

					<Tooltip v-else>
						{{ $p('Organisation.Entity.Name') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- HR-->
			<li v-if="hasModule([AxosModules.Hr, AxosModules.Employee])">
				<NuxtLink
					to="/employees"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/employees') ||
							isActive('/allergies') ||
							isActive('/hr') ||
							isActive('/employee-meetings'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_HR"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Core.Navigation.HR') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.HR') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- CRM-->
			<li v-if="hasModule(AxosModules.Crm)">
				<NuxtLink
					to="/accounts"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/account') ||
							isActive('/contacts') ||
							isActive('/crm'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_CRM"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Core.Navigation.CRM') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.CRM') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- Sales-->
			<li v-if="hasModule(AxosModules.Sales)">
				<NuxtLink
					to="/leads"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/sales') || isActive('/leads'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_SALES"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Core.Navigation.Sales') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Sales') }}
					</Tooltip>
				</NuxtLink>
			</li>
			<li v-if="hasModule(AxosModules.Finance)">
				<NuxtLink
					to="/products"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/finance') || isActive('/products'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_FINANCE"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Core.Navigation.Finance') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Finance') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<li class="grow"></li>

			<li v-if="hasModule(AxosModules.Link)">
				<NuxtLink
					to="/links"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/links'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_LINKS"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Core.Navigation.Links') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Links') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- Admin -->
			<li v-if="isAdmin">
				<NuxtLink
					to="/admin"
					:class="{
						[`border-slate-800 bg-slate-800/60 text-white`]:
							isActive('/admin'),
					}"
					class="group/tooltip ax-sidemenu-item"
				>
					<Icon
						:name="ICON_ADMIN"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showLabels">
						{{ $p('Core.Navigation.Admin') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Admin') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- Ideas-->
			<Idea :show-labels="showLabels" />

			<!-- Toggle sidebar labels-->
			<li class="mt-2 border-t border-slate-600" v-if="isLg">
				<button
					@click="toggleSidebarLabels"
					class="group/tooltip ax-sidemenu-item"
					:class="{
						'mt-0.5': showLabels,
						'mt-1': !showLabels,
					}"
				>
					<Icon
						:name="
							showLabels
								? ICON_CHEVRON_LEFT_DOUBLE
								: ICON_CHEVRON_RIGHT_DOUBLE
						"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<Tooltip>
						<template v-if="showLabels">
							{{ $s('Core.Navigation.CollapseSidebar') }}
						</template>
						<template v-else>
							{{ $s('Core.Navigation.ExpandSidebar') }}
						</template>
					</Tooltip>
				</button>
			</li>
		</ul>
	</nav>
</template>

<script setup lang="ts">
const { hasModule, isEmployeeManager, isAdmin } = useAccessControl();

const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const breakpoints = useBreakpoints({
	lg: 1024,
});
const isLg = breakpoints.greater('lg');

const showLabels = computed(() => showSidebarLabels.value && isLg.value);

const toggleSidebarLabels = () => {
	showSidebarLabels.value = !showSidebarLabels.value;
};

const route = useRoute();
function isActive(path: string) {
	return route.path.startsWith(path);
}
</script>
