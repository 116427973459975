export default function useChartUtils() {
	type ChartColor = (typeof chartColors)[number];

	const chartColors = [
		'#0059FF',
		'#1FADA5',
		'#EBC41F',
		'#FF735A',
		'#0503A3',
		'#010C32',
		'#F883EF',
		'#9E7E85',
		'#FF4A73',
	];

	function getChartColor(index: number): ChartColor {
		return chartColors[index % chartColors.length];
	}

	return { chartColors, getChartColor };
}
