<template>
	<input
		v-if="context"
		v-bind="context.attrs"
		:id="context.node.props.id"
		:uid="context.node.props.id"
		:disabled="context.disabled"
		:value="formattedValue"
		@input="onInput"
		@blur="onBlur"
		@keydown="onKeyDown"
		maxlength="5"
		placeholder="HH:MM"
		class="time-input"
	/>
	<p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
</template>

<script setup lang="ts">
const props = defineProps({
	context: Object,
});

// Local state
const errorMessage = ref('');
const internalValue = ref(props.context?._value);

// Watch for external changes to the value
watch(
	() => props.context?._value,
	(newValue) => {
		internalValue.value = newValue;
	},
);

// Format the value for display
const formattedValue = computed(() => {
	if (!internalValue.value) return '';
	const [hours, minutes] = internalValue.value.split(':');
	return `${hours || ''}${minutes !== undefined ? `:${minutes}` : ''}`;
});
// Validate the input value
const validateTime = (value: string) => {
	const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
	if (!regex.test(value)) return false;

	const [hours, minutes] = value.split(':').map(Number);
	return hours < 24 || (hours === 24 && minutes === 0);
};

// Handle input event
function onInput(event: any) {
	let inputValue = event.target.value;

	if (inputValue === '') {
		props.context!.node.input(null);
		return;
	}

	// Check if the user has typed two digits and the colon isn't present yet
	if (inputValue.length === 2 && !inputValue.includes(':')) {
		// Store the last character typed
		const lastChar = inputValue.charAt(inputValue.length - 1);

		// If the last character is a digit, wait for the next input
		if (!isNaN(lastChar)) {
			// Allow the user to add the colon manually
			return;
		}
	}

	// If the user types a number after two digits, add the colon
	if (inputValue.length === 3 && !inputValue.includes(':')) {
		inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(2)}`;
	}

	// Update the internal value and handle validation
	internalValue.value = inputValue;

	// Validate the time format if a colon is present
	if (validateTime(inputValue)) {
		errorMessage.value = '';
		const fullTime = `${inputValue}:00`;
		internalValue.value = fullTime;

		props.context!.node.input(fullTime);
	} else if (inputValue.length === 5) {
		// If the input is 5 characters long but invalid, show an error
		errorMessage.value = $s('Core.Info.InvalidTime');
	}
}

// Handle blur event for final validation and correction
function onBlur(event: any) {
	const inputValue = event.target.value;

	if (inputValue !== '' && !validateTime(inputValue)) {
		errorMessage.value = $s('Core.Info.InvalidTime');
	} else {
		errorMessage.value = '';
	}
}

function onKeyDown(event: KeyboardEvent) {
	// Allow keyboard shortcuts (Ctrl/Cmd + key)
	if (event.ctrlKey || event.metaKey) {
		return;
	}

	// Allow only numbers, colon, and specific control keys
	if (
		!/^[0-9:]$/.test(event.key) &&
		![
			'Backspace',
			'Delete',
			'ArrowLeft',
			'ArrowRight',
			'Tab',
			'Enter',
		].includes(event.key)
	) {
		event.preventDefault();
	}

	if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
		event.preventDefault();
		const input = event.target as HTMLInputElement;
		const cursorPosition = input.selectionStart || 0;
		const [hours, minutes] = (internalValue.value || '00:00:00').split(':');
		let newHours = parseInt(hours);
		let newMinutes = parseInt(minutes);

		if (cursorPosition <= 2) {
			// Cursor is in the hours section
			newHours = adjustTime(
				newHours,
				event.key === 'ArrowUp' ? 1 : -1,
				0,
				23,
			);
		} else {
			// Cursor is in the minutes section
			newMinutes = adjustTime(
				newMinutes,
				event.key === 'ArrowUp' ? 1 : -1,
				0,
				59,
			);
		}

		const newValue = `${padZero(newHours)}:${padZero(newMinutes)}:00`;
		internalValue.value = newValue;

		props.context!.node.input(newValue);

		// Use nextTick to ensure the DOM has updated before setting the cursor position
		nextTick(() => {
			input.setSelectionRange(cursorPosition, cursorPosition);
		});
	}
}

function adjustTime(
	value: number,
	change: number,
	min: number,
	max: number,
): number {
	let newValue = value + change;
	if (newValue > max) newValue = min;
	if (newValue < min) newValue = max;
	return newValue;
}

function padZero(num: number): string {
	return num.toString().padStart(2, '0');
}
</script>

<style scoped>
@reference '~/assets/css/style.css';

input {
	@apply -m-2 h-10 w-full appearance-none border-none bg-transparent p-2 text-sm text-inherit outline-hidden! placeholder:text-xs placeholder:text-slate-400 focus:ring-0;
}

.error-message {
	@apply absolute -bottom-5 left-1 text-xs text-red-500;
}
</style>
