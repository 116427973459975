<template>
	<div class="flex flex-wrap gap-0.5 text-slate-600">
		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleBold().run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:disabled="!editor.can().chain().focus().toggleBold().run()"
			:class="{ 'is-active': editor.isActive('bold') }"
		>
			<Icon class="h-5 w-5" :name="ICON_BOLD" />
		</button>
		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleItalic().run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:disabled="!editor.can().chain().focus().toggleItalic().run()"
			:class="{ 'is-active': editor.isActive('italic') }"
		>
			<Icon class="h-5 w-5" :name="ICON_ITALIC" />
		</button>
		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:class="{
				'is-active': editor.isActive('heading', {
					level: 1,
				}),
			}"
		>
			<Icon class="h-5 w-5" :name="ICON_H1" />
		</button>
		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:class="{
				'is-active': editor.isActive('heading', {
					level: 2,
				}),
			}"
		>
			<Icon class="h-5 w-5" :name="ICON_H2" />
		</button>
		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:class="{
				'is-active': editor.isActive('heading', {
					level: 3,
				}),
			}"
		>
			<Icon class="h-5 w-5" :name="ICON_H3" />
		</button>

		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleBulletList().run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:class="{ 'is-active': editor.isActive('bulletList') }"
		>
			<Icon class="h-5 w-5" :name="ICON_LIST_BULLETS" />
		</button>
		<button
			tabindex="-1"
			type="button"
			@click="editor.chain().focus().toggleOrderedList().run()"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
			:class="{ 'is-active': editor.isActive('orderedList') }"
		>
			<Icon class="h-5 w-5" :name="ICON_LIST_NUMBERS" />
		</button>
		<button
			tabindex="-1"
			type="button"
			@click="emit('uploadImage')"
			class="menubar-button transition-colors hover:bg-slate-300 focus:bg-slate-300"
		>
			<Icon class="h-5 w-5" :name="ICON_IMAGE" />
		</button>

		<Menu
			v-if="hasModule(AxosModules.Ai)"
			as="div"
			class="relative ml-auto"
		>
			<MenuButton
				class="inline-flex w-full items-center justify-center px-1 py-1 text-sm font-semibold text-primary transition-all hover:text-primary-800 enabled:hover:bg-slate-200"
				:class="{
					'opacity-25': editor.state.selection.empty,
				}"
				:disabled="editor.state.selection.empty"
			>
				<Icon
					:name="ICON_SPARKLES"
					class="mr-2 h-4 w-4"
					aria-hidden="true"
				/>
				Axos AI
				<Icon
					:name="ICON_CHEVRON_DOWN"
					class="-mr-1 h-4 w-4"
					aria-hidden="true"
				/>

				<LoadingSpinner v-if="aiLoading" class="ml-3" />
			</MenuButton>

			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95"
			>
				<MenuItems
					class="absolute left-0 z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-sm bg-white ring-1 shadow-lg ring-slate-200 focus:outline-hidden"
				>
					<div class="divide-y divide-gray-200 p-1.5">
						<div>
							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiFixSpellingAndGrammar(aiOptions)
											.run()
									"
								>
									<Icon
										:name="ICON_FILES"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Fiks staving
								</button>
							</MenuItem>
						</div>

						<div class="py-1">
							<div
								class="my-1 px-4 text-xs font-bold text-slate-500 uppercase"
							>
								Formulering
							</div>

							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiAdjustTone(
												'profesjonell',
												aiOptions,
											)
											.run()
									"
								>
									<Icon
										:name="ICON_MICROPHONE"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Mer profesjonell
								</button>
							</MenuItem>
							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiAdjustTone('folkelig', aiOptions)
											.run()
									"
								>
									<Icon
										:name="ICON_MICROPHONE"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Mer folkelig
								</button>
							</MenuItem>
							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiAdjustTone(
												'informativ',
												aiOptions,
											)
											.run()
									"
								>
									<Icon
										:name="ICON_MICROPHONE"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Mer informativ
								</button>
							</MenuItem>
						</div>

						<div class="py-1">
							<div
								class="my-1 px-4 text-xs font-bold text-slate-500 uppercase"
							>
								Oversett
							</div>

							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiTranslate('en', aiOptions)
											.run()
									"
								>
									<Icon
										:name="ICON_TRANSLATE"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Engelsk
								</button>
							</MenuItem>
							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiTranslate('de', aiOptions)
											.run()
									"
								>
									<Icon
										name="lucide:languages"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Tysk
								</button>
							</MenuItem>
							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiTranslate('es', aiOptions)
											.run()
									"
								>
									<Icon
										:name="ICON_TRANSLATE"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Spansk
								</button>
							</MenuItem>
							<MenuItem v-slot="{ active }">
								<button
									:class="[
										active
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-700',
										'group flex w-full items-center rounded-sm px-4 py-2 text-sm',
									]"
									@click="
										editor
											.chain()
											.focus()
											.aiTranslate('no', aiOptions)
											.run()
									"
								>
									<Icon
										:name="ICON_TRANSLATE"
										class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
									Norsk
								</button>
							</MenuItem>
						</div>
					</div>
				</MenuItems>
			</transition>
		</Menu>
	</div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { TextOptions, Tone } from '@tiptap-pro/extension-ai';
import { Editor } from '@tiptap/vue-3';

const props = defineProps<{
	editor: Editor;
	aiOptions: TextOptions;
	aiLoading: boolean;
}>();

const { hasModule } = useModules();

const emit = defineEmits<{
	(e: 'uploadImage'): void;
}>();
</script>

<style scoped>
@reference '~/assets/css/style.css';

.is-active {
	@apply bg-slate-300 text-slate-900;
}
.editor .ProseMirror {
	@apply outline-hidden;
}
button {
	@apply rounded px-1.5 pt-0.5 pb-1 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-inherit;
}
</style>
