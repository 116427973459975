<template>
	<div class="search-hit block">
		<div
			class="mx-2 flex cursor-pointer items-center gap-x-2 rounded-md border px-2.5 py-1.5"
			:class="{
				'border-primary-200 bg-primary-100 text-slate-900': active,
				'border-transparent bg-white text-slate-700': !active,
			}"
		>
			<template v-if="showIcon">
				<!-- Avatar section -->
				<div v-if="record.imageUrl" class="shrink-0">
					<ImageWithLoader
						:src="record.imageUrl"
						:height="40"
						:width="40"
						class="aspect-square size-10 rounded-full object-cover"
						:alt="record.title"
					/>
				</div>

				<div
					v-else
					:class="[
						'relative flex size-10 items-center justify-center rounded-full text-sm font-semibold',
						textColorClass,
					]"
				>
					{{ initials }}
				</div>
			</template>

			<!-- Content section -->
			<div class="flex min-w-0 flex-col gap-y-1 leading-none">
				<h3
					class="truncate font-semibold"
					v-dompurify-html="
						$s(record?.formatted?.title || record.title)
					"
				></h3>

				<div
					v-if="record.description"
					class="truncate text-xs text-slate-400"
					v-dompurify-html="
						$s(record?.formatted?.description || record.description)
					"
				></div>
			</div>

			<Chip
				class="ml-auto shrink-0 bg-white pl-2 text-slate-400"
				:value="$s(`${record.entityName}.Entity.Name`)"
				size="small"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
const props = defineProps<{
	record: any;
	active: boolean;
	showIcon: boolean;
}>();

const initials = computed(() => {
	if (!props.record) return '';
	return $toInitials(props.record.title, { maxLength: 2 });
});

const textColorClass = computed(() => {
	if (!initials.value) return '';
	return $textToColor(initials.value);
});


</script>
