<template>
	<FormAutocomplete
		:id="context.id"
		:options="internalOptions"
		:model-value="context._value"
		:multiselect="context.multiselect"
		:is-loading="isLoading"
		:disabled="context.disabled"
		@update:modelValue="handleInput"
		:description="context.description"
		:display-formatter="context.displayFormatter"
		:option-formatter="context.optionFormatter"
		:image="context.image"
		:scroll-to-highlighted="context.scrollToHighlighted"
		:open-on-mount="openOnMount"
		:show-create="context.showCreate"
		:placeholder="context.placeholder"
		@on-popover-toggle="handlePopoverToggle"
		@create="handleCreateNew"
		:selection-appearance="context.selectionAppearance"
	/>
</template>
<script setup lang="ts">
import { DropdownItemInput } from '~/types/dropdown';

const props = defineProps<{ context: any }>();

const internalOptions = ref<any[]>([]);
const isLoading = ref(false);

const openOnMount = ref(false);
props.context.handlers.setOpenOnMount = (value: boolean) => {
	openOnMount.value = value;
};

await getOptions();

async function getOptions() {
	if (props.context?.optionsLoader) {
		if (typeof props.context.optionsLoader !== 'function') {
			throw new Error('optionsLoader must be a function');
		}

		isLoading.value = true;
		const options = (await props.context.optionsLoader({
			waitForData: true,
		})) as DropdownItemInput[];
		internalOptions.value = options;
		isLoading.value = false;
	} else if (props.context?.options) {
		internalOptions.value = props.context.options as DropdownItemInput[];
	}
}

// Refresh options if the value is not in the internal options
watch(
	() => props.context._value,
	(value: any) => {
		if (value && !internalOptions.value.find((o) => o.id === value)) {
			getOptions();
		}
	}
);

watch(
	() => props.context.createdValue,
	async (value: any) => {
		if (!value) return;

		await getOptions();

		if (props.context.multiselect) {
			handleInput([...props.context._value, value]);
		} else {
			handleInput(value);
		}
	}
);

function handleInput(value: string | number | (string | number)[] | null) {
	props.context?.node.input(value);
}

function handlePopoverToggle(visible: boolean) {
	if (visible) {
		getOptions();
	}
	props.context?.onPopoverToggle?.(visible);
}

function handleCreateNew(name?: string) {
	props.context?.onCreateNew?.(name);
}
</script>
