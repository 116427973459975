export function $maskText(
	text?: string | number | undefined | null,
	mask: string = '*',
) {
	if (!text) {
		return '';
	}

	const maskedText = text.toString().replace(/./g, mask);
	return maskedText;
}
export function $toInitials(
	value: string | number,
	options?: { maxLength?: number },
): string {
	if (value === null || value === undefined) {
		return '';
	}

	const stringValue = value.toString();

	// Only allow letters including Norwegian characters
	const cleanValue = stringValue.replace(/[^a-zæøåA-ZÆØÅ\s]/g, '');

	if (!cleanValue.includes(' ')) {
		return cleanValue.charAt(0).toUpperCase();
	}

	const names = cleanValue.split(/\s+/);
	const initials = names.map((name) => name.charAt(0).toUpperCase()).join('');
	const maxLength = options?.maxLength ?? 2;

	return initials.slice(0, maxLength);
}

/**
 * Formats a number using language-specific thousand separators
 * @param value The number to format
 * @param options Optional Intl.NumberFormatOptions
 * @returns Formatted number string
 */
export function $formatNumber(
	value: number | string | null | undefined,
	options: Intl.NumberFormatOptions = {},
): string {
	if (!value) {
		return '';
	}

	const defaultOptions: Intl.NumberFormatOptions = {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	};

	const { currentLanguageId } = useAppLanguage();
	const mergedOptions = { ...defaultOptions, ...options };
	const locale = currentLanguageId.value !== 1 ? 'en-US' : 'nb-NO';

	return new Intl.NumberFormat(locale, mergedOptions).format(Number(value));
}

/**
 * Formats a number as Norwegian currency (NOK)
 * @param amount The number to format
 * @param options Intl.NumberFormatOptions
 * @returns Formatted currency string
 */
const { instanceSettings } = useInstanceSettings();
export function $formatCurrency(
	amount: number | string | null | undefined,
	options: Intl.NumberFormatOptions = {},
): string {
	const defaultOptions: Intl.NumberFormatOptions = {
		style: 'currency',
		currency: instanceSettings.value.currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	};

	if (!amount) {
		return '';
	}

	const mergedOptions = { ...defaultOptions, ...options };
	const locale = mergedOptions.currency === 'NOK' ? 'nb-NO' : 'en-US';

	return new Intl.NumberFormat(locale, mergedOptions).format(Number(amount));
}
export function $textToColor(text: string): string {
	if (!text) {
		return 'bg-ax-custom-1 text-ax-custom-1-contrast';
	}

	function hashCode(str: string): number {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			const char = str.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash = hash & hash;
		}
		return Math.abs(hash);
	}

	const colorVariants = [
		'bg-ax-1-200 border border-ax-1-300 text-slate-900',
		'bg-ax-2-200 border border-ax-2-300 text-slate-900',
		'bg-ax-3-200 border border-ax-3-300 text-slate-900',
		'bg-ax-4-200 border border-ax-4-300 text-slate-900',
		'bg-ax-5-200 border border-ax-5-300 text-slate-900',
		'bg-ax-6-100 border border-ax-6-200/50 text-slate-900',
		'bg-ax-7-200 border border-ax-7-300 text-slate-900',
		'bg-ax-8-200 border border-ax-8-300/50 text-slate-900',
		'bg-ax-9-200 border border-ax-9-300 text-slate-900',
		'bg-ax-10-200 border border-ax-10-300 text-slate-900',
	];

	const colorIndex = hashCode(text) % colorVariants.length;
	return colorVariants[colorIndex];
}

export default function useTextUtils() {
	/**
	 * Converts a string to PascalCase
	 */
	function toPascalCase(text: string) {
		if (typeof text !== 'string') {
			return undefined;
		}

		return text?.charAt(0).toUpperCase() + text?.slice(1);
	}

	/**
	 * Converts a string to camelCase
	 */
	function toCamelCase(text: string) {
		if (typeof text !== 'string') {
			return undefined;
		}

		return text?.charAt(0).toLowerCase() + text?.slice(1);
	}

	/**
	 * Converts a name to initials
	 */
	function toInitials(firstName: string, lastName: string) {
		if (!firstName || !lastName) {
			return '';
		}

		const firstNames = firstName.split(/[\s-]/);
		const lastNames = lastName.split(/[\s-]/);

		const firstInitias = firstNames.map((name) => name.charAt(0));
		const lastInitials = lastNames.map((name) => name.charAt(0));

		const initials = `${firstInitias.join('')}${lastInitials.join('')}`;

		return initials.toUpperCase();
	}

	/**
	 * Converts a norwegian legal number to a formatted number
	 */
	function toFormattedLegalNo(number: string) {
		if (!number) {
			return '';
		}

		const formattedNumber = number.replace(
			/(\d{3})(\d{3})(\d{3})/,
			'$1 $2 $3',
		);

		return formattedNumber;
	}

	return {
		toPascalCase,
		toCamelCase,
		toInitials,
		toFormattedLegalNo,
	};
}
