<template>
	<div class="mt-[var(--header-height)] lg:mt-[var(--header-height-lg)]">
		<AppToasts />
		<AppNotifications />

		<AppHeader />
		<ErrorPage404 v-if="error?.statusCode === 404" :error="error" />
		<ErrorPage403 v-else-if="error?.statusCode === 403" :error="error" />
		<ErrorPageDefault v-else :error="error" />
	</div>
</template>
<script setup lang="ts">
const props = defineProps({
	error: Object,
});

const { localizationData, suspense } = useLocalizationData();
await suspense();

const { setResourcesData, setLanguageId, missingResources } = useLocalization();
setResourcesData(localizationData.value!);
</script>
