import {
	dehydrate,
	DehydratedState,
	hydrate,
	matchQuery,
	MutationCache,
	Query,
	QueryCache,
	QueryClient,
	QueryKey,
	VueQueryPlugin,
	VueQueryPluginOptions,
} from '@tanstack/vue-query';

export default defineNuxtPlugin((nuxt) => {
	const vueQueryState = useState<DehydratedState | null>('vue-query');

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				refetchOnReconnect: 'always',
				staleTime: 0,
				placeholderData: (prev: any) => prev,
				gcTime: 1000 * 60 * 60 * 24, // 1 day
				retry: false,
			},
			mutations: {
				retry: false,
			},
		},

		// Automatically invalidate all non-static queries when a mutation is successful
		mutationCache: new MutationCache({
			onSuccess: (_data, _variables, _context, mutation) => {
				const excludeInvalidate = mutation.meta?.excludeInvalidate;
				const nonStaticQueries = (query: Query) => {
					if (excludeInvalidate) {
						const isArrayOfMutationKeys = excludeInvalidate.some(
							(key) => Array.isArray(key)
						);

						if (isArrayOfMutationKeys) {
							const arrayOfExcludes =
								excludeInvalidate as QueryKey[];

							const isMatching = arrayOfExcludes.some((exclude) =>
								matchQuery({ queryKey: exclude }, query)
							);

							if (isMatching) {
								return false;
							}
						} else {
							const isMatching = matchQuery(
								{ queryKey: excludeInvalidate },
								query
							);

							if (isMatching) {
								return false;
							}
						}
					}

					const defaultStaleTime =
						queryClient.getQueryDefaults(query.queryKey)
							.staleTime ?? 0;
					const staleTimes = query.observers
						.map((observer) => observer.options.staleTime)
						.filter((staleTime) => staleTime !== undefined);

					const staleTime =
						query.getObserversCount() > 0 && staleTimes.length > 0
							? Math.min(...staleTimes)
							: defaultStaleTime;

					const shouldInvalidate =
						staleTime !== Number.POSITIVE_INFINITY;

					return shouldInvalidate;
				};

				queryClient.invalidateQueries({
					predicate: nonStaticQueries,
				});
			},
		}),

		queryCache: new QueryCache({
			onError: (error, query) => {
				if (
					error.statusCode === 401 &&
					!query.queryKey.includes('authStateMe')
				) {
					queryClient.invalidateQueries({
						queryKey: ['authStateMe'],
					});
				}
			},
		}),
	});

	const options: VueQueryPluginOptions = {
		queryClient,
	};

	nuxt.vueApp.use(VueQueryPlugin, options);

	if (process.server) {
		nuxt.hooks.hook('app:rendered', () => {
			vueQueryState.value = dehydrate(queryClient);
		});
	}

	if (process.client) {
		nuxt.hooks.hook('app:created', () => {
			hydrate(queryClient, vueQueryState.value);
		});
	}
});
