<template>
	<NuxtLink
		to="/"
		class="relative flex h-12 shrink-0 items-center justify-center"
		:class="{
			'w-10': !showSidebarLabels,
			'w-18': showSidebarLabels && isLg,
		}"
	>
		<NuxtImg
			:src="`/axos-logo-header-${typeOfLogo}.svg`"
			alt="Axos logo"
			class="h-7"
		/>
		<span
			class="pointer-events-none absolute bottom-5 left-[53%] rounded-full px-1 text-[8px] leading-4 font-semibold text-white uppercase shadow-sm"
			:class="[IS_DEV ? 'bg-red-600' : 'bg-green-400']"
		>
			{{ IS_DEV ? 'DEV' : 'Beta' }}
		</span>
	</NuxtLink>
</template>

<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		typeOfLogo?: 'white' | 'blue';
	}>(),
	{
		typeOfLogo: 'blue',
	},
);
const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const breakpoints = useBreakpoints({
	lg: 1024,
});
const isLg = breakpoints.greater('lg');

const { IS_DEV } = useEnvironment();
</script>
