import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPowerTable, LazyPowerTableBadgeColumn, LazyPowerTableBooleanColumn, LazyPowerTableButtonColumn, LazyPowerTableButtonLinkColumn, LazyPowerTableDateColumn, LazyPowerTableEntityColumn, LazyPowerTableImageColumn, LazyPowerTableLinkColumn, LazyPowerTableLinkWrapper, LazyPowerTableLoader, LazyPowerTableNumberColumn, LazyPowerTablePagination, LazyPowerTablePriceColumn, LazyPowerTableTextColumn, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["PowerTable", LazyPowerTable],
["PowerTableBadgeColumn", LazyPowerTableBadgeColumn],
["PowerTableBooleanColumn", LazyPowerTableBooleanColumn],
["PowerTableButtonColumn", LazyPowerTableButtonColumn],
["PowerTableButtonLinkColumn", LazyPowerTableButtonLinkColumn],
["PowerTableDateColumn", LazyPowerTableDateColumn],
["PowerTableEntityColumn", LazyPowerTableEntityColumn],
["PowerTableImageColumn", LazyPowerTableImageColumn],
["PowerTableLinkColumn", LazyPowerTableLinkColumn],
["PowerTableLinkWrapper", LazyPowerTableLinkWrapper],
["PowerTableLoader", LazyPowerTableLoader],
["PowerTableNumberColumn", LazyPowerTableNumberColumn],
["PowerTablePagination", LazyPowerTablePagination],
["PowerTablePriceColumn", LazyPowerTablePriceColumn],
["PowerTableTextColumn", LazyPowerTableTextColumn],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
