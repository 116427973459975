export default defineNuxtRouteMiddleware(async (to, from) => {
	const { IS_DEV, IS_TEST } = useEnvironment();

	if (IS_TEST) {
		return;
	}

	// Disable client-setup in production
	if (IS_DEV && to.path === '/setup/client') {
		return;
	}

	const ignoredPaths = [
		'/no-clients-available',
		'/auth/login',
		'/server-error',
		'/login-error',
	];
	if (ignoredPaths.includes(to.path)) {
		return;
	}

	const config = useRuntimeConfig();
	if (to.path === '/login' && to.query.invitation && to.query.organization) {
		return navigateTo(
			`${config.public.BFF_URL}/login?invitation=${to.query.invitation}&organization=${to.query.organization}`,
			{
				external: true,
			}
		);
	} else if (to.path === '/login' && to.query.iss) {
		return navigateTo('/');
	}

	const { login } = useAuthAxos();

	const { isAuthenticated, error, suspense } = useIsAuthenticated();
	await suspense();

	if (!isAuthenticated.value) {
		const { data, error } = await useFetch('/', {
			baseURL: config.public.BFF_URL,
		});

		const isServerDown =
			error.value?.status === 503 ||
			error.value?.message === 'Network Error' ||
			error.value?.message?.includes('Failed to fetch');

		if (isServerDown) {
			return navigateTo('/server-error?code=ECONNREFUSED');
		}

		return login();
	}
});
