import HighchartsVue from 'highcharts-vue';
import * as Highcharts from 'highcharts';

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(HighchartsVue);

	const { chartColors } = useChartUtils();

	Highcharts.setOptions({
		chart: {
			style: {
				fontFamily: 'figtree',
			},
		},
		colors: chartColors,
	});
});
