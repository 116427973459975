<template>
	<!-- Search bar -->
	<div class="ml-4 flex-1">
		<TransitionRoot
			:show="open"
			as="template"
			@after-leave="query = ''"
			appear
		>
			<div
				class="mt-2.5 transition-all duration-100 ease-out"
				:class="{
					'lg:w-full lg:max-w-3xl': showSearch || focused,
					'max-w-xl lg:w-72': !showSearch,
					'fixed top-0 right-4 left-14 z-50 max-w-none!':
						(focused || showSearch) && isSmallScreen,
				}"
			>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95"
				>
					<div class="w-full transform rounded-xl transition-all">
						<Combobox v-model="selected" ref="menuRef" nullable>
							<div class="relative">
								<Icon
									:name="ICON_SEARCH"
									class="pointer-events-none absolute top-2 left-4 h-5 w-5 text-slate-500"
									aria-hidden="true"
								/>

								<ComboboxInput
									ref="inputRef"
									class="h-9 w-full rounded-xl border border-[var(--app-search-border)] bg-[var(--app-search-bg)] pr-4 pl-11 text-slate-700 ring-0 placeholder:text-slate-500 focus-within:ring-2 focus-within:ring-primary-500 hover:border-slate-300 sm:text-sm"
									placeholder="Søk …"
									:display-value="() => query"
									@change="
										query = $event.target.value;
										showSearch = true;
									"
								/>

								<button
									v-if="query !== ''"
									@click="
										showSearch = false;
										showMenu = false;
										query = '';
									"
									class="absolute top-1 right-2 appearance-none p-1"
								>
									<Icon
										:name="ICON_X"
										class="h-5 w-5 text-slate-500"
										aria-hidden="true"
									/>
								</button>
							</div>

							<!-- Search results -->
							<div
								v-if="query !== ''"
								ref="searchResultsRef"
								class="mt-1 rounded-xl border bg-white shadow-xl"
							>
								<div
									class="mt-px flex ax-scrollbar-thin gap-x-2 overflow-y-scroll px-4 pt-3"
								>
									<Chip
										v-if="
											(results?.estimatedTotalHits ?? 0) >
											0
										"
										:value="$s('Core.Label.All')"
										:count="results?.estimatedTotalHits"
										:active="entityFilter === null"
										@click="
											entityFilter = null;
											showSearch = true;
										"
										size="small"
									/>
									<Chip
										v-for="chip of resultChips"
										:value="chip.label"
										:count="chip.count"
										:active="entityFilter === chip.key"
										@click="
											entityFilter = chip.key;
											showSearch = true;
										"
										size="small"
									/>
								</div>

								<ComboboxOptions
									static
									class="mt-2 max-h-[calc(100svh-130px)] overflow-y-auto rounded-b-xl bg-white pb-2"
									@keydown="handleKeydown"
								>
									<div
										v-if="isSearching"
										class="flex items-center justify-center gap-x-2 pb-2 text-center text-slate-500"
									>
										<LoadingSpinner />
										{{ $s('Core.Label.Searching') }}
									</div>

									<div
										v-else-if="hits?.length === 0"
										class="pb-2 text-center text-slate-500"
									>
										<div
											class="pb-2 text-center text-slate-500"
										>
											{{ $s('Core.Label.NoResults') }}
										</div>
									</div>

									<!-- Primary hits section -->

									<template v-else>
										<div
											v-if="primaryHits.length > 0"
											class="mt-2"
										>
											<ComboboxOption
												v-for="record of primaryHits.slice(
													0,
													5,
												)"
												v-slot="{ active }"
												:key="record.rowId"
												:value="record"
												as="template"
											>
												<AppSearchResultItem
													:record="record"
													:active="active"
													:show-icon="true"
												/>
											</ComboboxOption>
										</div>

										<!-- Divider if both sections have content -->
										<div
											v-if="
												primaryHits.length > 0 &&
												otherHits.length > 0
											"
											class="my-2 border-t border-slate-200"
										/>

										<!-- Other hits section -->
										<template v-if="otherHits.length > 0">
											<div class="px-5 pt-2">
												<h3
													class="mb-2 text-xs font-semibold text-slate-400 uppercase"
												>
													{{
														$s(
															'Core.Label.MoreResults',
														)
													}}
												</h3>
											</div>
											<ComboboxOption
												v-for="record of otherHits.slice(
													0,
													5,
												)"
												v-slot="{ active }"
												:key="record.rowId"
												:value="record"
												as="template"
												@click="
													inputRef.value?.$el.blur()
												"
											>
												<AppSearchResultItem
													:record="record"
													:active="active"
													:show-icon="false"
												/>
											</ComboboxOption>

											<div
												v-if="otherHits.length > 5"
												class="flex justify-end border-t px-5 pt-2 pb-2"
											>
												<Button
													link
													:to="`/search?query=${query}`"
													size="small"
													appearance="link"
													:icon="ICON_ARROW_RIGHT"
													icon-placement="right"
													@click="
														query = '';
														showMenu = false;
														showSearch = false;
														focused = false;
													"
												>
													{{
														$s(
															'Core.Button.ShowAllSearchResults',
														)
													}}
												</Button>
											</div>
										</template>
									</template>
								</ComboboxOptions>
							</div>
						</Combobox>
					</div>
				</TransitionChild>
			</div>
		</TransitionRoot>
	</div>
</template>

<script setup lang="ts">
interface SearchRecord {
	rowId: string;
	entityId: number;
	url: string;
}

import {
	Combobox,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';

import {
	onClickOutside,
	useMagicKeys,
	useFocus,
	useBreakpoints,
} from '@vueuse/core';

const ENTITY_TYPE_MAP: Record<number, string> = {
	1: 'Employee',
	8: 'Account',
	47: 'Contact',
};

const inputRef = ref<any>();
const router = useRouter();
const menuRef = ref<HTMLDivElement | null>();
const searchResultsRef = ref<HTMLDivElement | null>();

onClickOutside(menuRef, (event) => {
	// Check if the click was inside the search results
	if (searchResultsRef.value?.contains(event.target as Node)) {
		return;
	}

	query.value = '';
	showMenu.value = false;
	showSearch.value = false;
});

const open = ref(true);

const breakpoints = useBreakpoints({
	lg: '1024px',
});

const isSmallScreen = breakpoints.smaller('lg');
const query = ref('');
const queryDebounced = refDebounced(query, 250);
const selected = ref<SearchRecord | null>(null);

const { focused } = useFocus(inputRef);

watch(selected, (val) => {
	if (val && val.url) {
		router.push(val.url);
		showMenu.value = false;
		query.value = '';
		entityFilter.value = null;
		inputRef.value?.$el.blur();

		showSearch.value = false;
	}
});

const entityFilter = ref<string | null>(null);
const { hits, results } = useGlobalSearch(queryDebounced, {
	entityFilter,
});

const resultChips = computed(() => {
	const facetResults = results.value?.facetDistribution;
	if (!facetResults) return [];

	return facetResults
		.map((facet) => ({
			label: $s(`${facet.entityName}.Entity.Name`),
			count: facet.totalHits,
			key: facet.entity,
		}))
		.filter((facet) => facet.count > 0);
});

const keys = useMagicKeys();

// Esc
const esc = keys['Escape'];
watch(esc, (v) => {
	if (v) {
		query.value = '';
		showMenu.value = false;
		showSearch.value = false;
		inputRef.value?.$el.blur();
	}
});

const showMenu = ref(false);
const showSearch = ref(false);

// Add loading state
const isSearching = computed(
	() => results.value === undefined && query.value !== '',
);

// Add keyboard navigation
function handleKeydown(e: KeyboardEvent) {
	if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
		e.preventDefault(); // Prevent scroll
	}
}

function isPrimaryEntity(record: any) {
	const entityType = ENTITY_TYPE_MAP[record.entityId];
	return ['Employee', 'Account', 'Contact'].includes(entityType);
}

const primaryHits = computed(() => hits.value?.filter(isPrimaryEntity) ?? []);

const otherHits = computed(
	() => hits.value?.filter((record) => !isPrimaryEntity(record)) ?? [],
);
</script>

<style>
@reference '~/assets/css/style.css';

.search-hit mark {
	@apply bg-inherit text-inherit;
}
</style>
