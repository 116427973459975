<template>
	<Section width="narrow" class="py-16!">
		<div class="text-center">
			<h1
				class="mt-4 text-3xl font-bold tracking-tight text-slate-900 sm:text-5xl"
			>
				Woops
			</h1>
			<p class="mt-6 text-base leading-7 text-slate-600">
				{{ $s('Core.Info.ErrorOccured') }}
			</p>
			<div class="mt-10 flex items-center justify-center gap-x-6">
				<Button @click="handleError">
					{{ $s('Core.Button.BackToHome') }}
				</Button>
			</div>
		</div>
	</Section>
</template>
<script setup lang="ts">
const props = defineProps({
	error: Object,
});

const handleError = () => (window.location.href = '/');
</script>
