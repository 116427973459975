<template>
	<PageBody>
		<Section width="narrow" class="py-16!">
			<div class="text-center">
				<p class="text-base font-semibold text-primary">403</p>
				<h1
					class="mt-4 text-3xl font-bold tracking-tight text-slate-900 sm:text-5xl"
				>
					Ingen tilgang
				</h1>
				<p
					v-if="error?.message"
					class="mt-6 text-base leading-7 text-slate-600"
				>
					{{ $s(error.message) }}
				</p>
				<p v-else class="mt-6 text-base leading-7 text-slate-600">
					Beklager, du har ikke tilgang til dette innholdet.
				</p>
				<div class="mt-10 flex items-center justify-center gap-x-6">
					<Button @click="handleError"> Tilbake til forsiden</Button>
				</div>
			</div>
		</Section>
	</PageBody>
</template>
<script setup lang="ts">
const props = defineProps({
	error: Object,
});

const emit = defineEmits<{
	(e: 'clearError'): void;
}>();

const handleError = () => (window.location.href = '/');
</script>
