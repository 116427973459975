<template>
	<Teleport to="body">
		<div
			v-if="toasts.length"
			aria-live="assertive"
			class="pointer-events-none fixed inset-0 bottom-2 z-9999 flex content-end items-end px-4 pt-7 sm:bottom-0 sm:items-start"
		>
			<div class="flex w-full flex-col items-end gap-y-4">
				<div
					v-for="toast in toasts"
					:key="toast.id"
					class="toast-notification pointer-events-auto w-full overflow-hidden rounded-lg shadow-lg sm:max-w-sm"
					:class="{
						'bg-green-500': toast.type === ToastType.SUCCESS,
						'bg-red-600': toast.type === ToastType.ERROR,
						'bg-blue-400': toast.type === ToastType.INFO,
					}"
					:aria-hidden="toast.isExiting"
					@click="remove(toast.id)"
				>
					<div class="flex items-start p-3">
						<div class="shrink-0">
							<Icon
								v-if="toast.type === ToastType.SUCCESS"
								:name="ICON_CHECK_CIRCLE"
								class="h-6 w-6 text-white"
								aria-hidden="true"
							/>

							<Icon
								v-if="toast.type === ToastType.ERROR"
								:name="ICON_X_CIRCLE"
								class="h-6 w-6 text-white"
								aria-hidden="true"
							/>

							<Icon
								v-if="toast.type === ToastType.INFO"
								:name="ICON_INFO"
								class="h-6 w-6 text-white"
								aria-hidden="true"
							/>
						</div>

						<p
							class="text mt-0.5 ml-2 flex-1 text-sm font-semibold text-white"
						>
							{{ $s(toast.message) }}
						</p>

						<div class="ml-4 flex shrink-0">
							<button
								type="button"
								class="inline-flex rounded-sm p-1 text-white"
								:class="{
									'bg-green-600':
										toast.type === ToastType.SUCCESS,
									'bg-red-700':
										toast.type === ToastType.ERROR,
									'bg-blue-600':
										toast.type === ToastType.INFO,
								}"
								@click="remove(toast.id)"
							>
								<span class="sr-only">{{
									$s('Core.Label.Close')
								}}</span>

								<Icon
									:name="ICON_X"
									class="h-4 w-4"
									aria-hidden="true"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>
<script setup lang="ts">
import { ToastType } from '~/composables/useToasts';

const { toasts, remove } = useToasts();
</script>

<style>
:root {
	--toast-animation-duration: 500ms;
	--toast-animation-easing: ease-out;
	--toast-translate-distance: 1rem;
	--toast-opacity-start: 0;
	--toast-opacity-end: 1;
}

.toast-notification {
	will-change: transform, opacity, display;
	animation: toast-enter var(--toast-animation-duration)
		var(--toast-animation-easing);
	transition:
		transform var(--toast-animation-duration) var(--toast-animation-easing),
		opacity var(--toast-animation-duration) var(--toast-animation-easing),
		display var(--toast-animation-duration);
	transition-behavior: allow-discrete;
}

.toast-notification[aria-hidden='true'] {
	opacity: var(--toast-opacity-start);
	display: none;
}

@media (prefers-reduced-motion: reduce) {
	.toast-notification {
		animation: none;
		transition: none;
		opacity: var(--toast-opacity-end);
	}

	.toast-notification[aria-hidden='true'] {
		display: none;
		transition: none;
	}
}

@starting-style {
	.toast-notification {
		opacity: var(--toast-opacity-start);
		transform: translateY(var(--toast-translate-distance));
	}
}

@keyframes toast-enter {
	from {
		opacity: var(--toast-opacity-start);
		transform: translateY(var(--toast-translate-distance));
	}
	to {
		opacity: var(--toast-opacity-end);
		transform: translateY(0);
	}
}
</style>
