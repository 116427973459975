export function useQuickNoteUtils() {
	const QUICK_NOTE_CLASS = {
		OPEN: 'is-quicknote-open',
		CLOSED: 'is-quicknote-closed',
	} as const;

	const STORAGE_KEY = 'quicknote-width';
	const minWidth = 300;
	const maxWidth = 640;

	const isQuickNoteOpen = useLocalStorage('isQuickNoteOpen', false);
	const quickNoteWidth = useLocalStorage(STORAGE_KEY, 400);

	function updateBodyClasses(isOpen: boolean) {
		const { classList } = document.body;
		classList.toggle(QUICK_NOTE_CLASS.OPEN, isOpen);
		classList.toggle(QUICK_NOTE_CLASS.CLOSED, !isOpen);
	}

	function toggleQuickNote() {
		isQuickNoteOpen.value = !isQuickNoteOpen.value;
		updateBodyClasses(isQuickNoteOpen.value);
	}

	function closeQuickNote() {
		isQuickNoteOpen.value = false;
		updateBodyClasses(false);
	}

	function initializeWidth() {
		const defaultWidth = parseInt(
			getComputedStyle(document.documentElement).getPropertyValue(
				'--quicknote-width',
			),
		);
		const width = quickNoteWidth.value ?? defaultWidth;
		const validWidth = Math.min(Math.max(width, minWidth), maxWidth);
		document.documentElement.style.setProperty(
			'--quicknote-width',
			`${validWidth}px`,
		);
	}

	function startResize(event: MouseEvent, quickNoteRef: HTMLElement | null) {
		event.preventDefault();

		const startX = event.clientX;
		const defaultWidth = parseInt(
			getComputedStyle(document.documentElement).getPropertyValue(
				'--quicknote-width',
			),
		);
		const startWidth = quickNoteRef?.offsetWidth ?? defaultWidth;

		function onMouseMove(e: MouseEvent) {
			if (!quickNoteRef) return;

			const delta = startX - e.clientX;
			const newWidth = Math.min(
				Math.max(startWidth + delta, minWidth),
				maxWidth,
			);
			document.documentElement.style.setProperty(
				'--quicknote-width',
				`${newWidth}px`,
			);
		}

		function onMouseUp(e: MouseEvent) {
			const finalWidth = quickNoteRef?.offsetWidth;
			if (finalWidth) {
				quickNoteWidth.value = finalWidth;
			}
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		}

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	}

	// Initialize body classes based on stored state
	if (isQuickNoteOpen.value) {
		updateBodyClasses(true);
	}

	return {
		isQuickNoteOpen,
		toggleQuickNote,
		closeQuickNote,
		initializeWidth,
		startResize,
	};
}
