<template>
	<main
		:class="{
			'2xl:max-w-[calc(100vw-var(--quicknote-width))]': isQuickNoteOpen,
		}"
	>
		<TransitionRoot as="template" :show="sidebarOpen">
			<TransitionChild
				as="template"
				enter="transition ease-out duration-150 transform"
				enter-from="-translate-x-full"
				enter-to="translate-x-0"
				leave="transition ease-out duration-150 transform"
				leave-from="translate-x-0"
				leave-to="-translate-x-full"
			>
				<aside
					aria-label="Sidebar"
					class="fixed inset-y-0 top-[var(--header-height)] z-50 flex shrink-0 lg:top-[var(--header-height-lg)] lg:z-40"
					:class="{
						'w-80': $slots.sidebar,
						'w-16': !$slots.sidebar,
					}"
				>
					<PageMenu />

					<!-- Sidebar menu -->
					<div
						v-if="$slots.sidebar"
						class="z-10 flex w-72 flex-col overflow-y-auto bg-[var(--page-sidemenu-bg)] p-4"
					>
						<slot name="sidebar"></slot>
					</div>
				</aside>
			</TransitionChild>
		</TransitionRoot>

		<!-- Main content -->
		<div
			class="relative flex min-h-[calc(100vh-var(--header-height))] flex-col transition-[transform,padding] duration-200 ease-out will-change-transform lg:min-h-[calc(100vh-var(--header-height-lg))] lg:translate-x-0"
			:class="{
				'lg:pl-80': $slots.sidebar,
				'lg:pl-16': !$slots.sidebar,
				'lg:pl-24': showLabels && !$slots.sidebar,
				'translate-x-80': sidebarOpen && $slots.sidebar,
				'translate-x-16': sidebarOpen && !$slots.sidebar,
				'before:absolute before:inset-0 before:z-50 before:bg-slate-900/50 before:transition-opacity before:duration-200':
					sidebarOpen && !isLg,
				'before:pointer-events-none before:opacity-0':
					!sidebarOpen && !isLg,
				'before:opacity-100': sidebarOpen && !isLg,
			}"
			@click="sidebarOpen && !isLg ? toggleSidebar() : null"
		>
			<!-- Header -->
			<header
				v-if="$slots.header"
				class="relative z-40 flex min-h-20 items-center bg-[var(--page-header-bg)] lg:min-h-28"
			>
				<PageContentWrapper :narrow="narrow">
					<slot name="header"></slot>
				</PageContentWrapper>
			</header>

			<!-- Content -->
			<div
				class="ax-scrollbar-thin flex-1 overflow-y-auto border-t lg:rounded-tl-xl lg:border-l lg:bg-slate-75"
			>
				<PageContentWrapper :narrow="narrow" is-main>
					<slot> </slot>
				</PageContentWrapper>
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
import { TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps<{
	narrow?: boolean;
}>();

const { sidebarOpen, toggleSidebar, setSidebarContent } = useSidebar();

// Quicknote
const { isQuickNoteOpen } = useQuickNoteUtils();

const breakpoints = useBreakpoints({
	lg: 1024,
});
const isLg = breakpoints.greater('lg');

watch(isLg, (value) => {
	sidebarOpen.value = value;
});

const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const showLabels = computed(() => showSidebarLabels.value && isLg.value);

const slots = useSlots();

watch(
	() => !!slots.sidebar,
	(hasSidebar) => {
		setSidebarContent(hasSidebar);
	},
	{ immediate: true },
);
</script>
