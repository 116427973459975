<template>
	<VueDatePicker
		v-bind="context.attrs"
		:id="context.node.props.id"
		:uid="context.node.props.id"
		:disabled="context.disabled"
		:model-value="internalValue"
		:text-input="{
			format: enableTimePicker
				? ['dd.MM.yyyy HH:mm', 'dd/MM/yyyy HH:mm']
				: ['dd.MM.yyyy', 'dd/MM/yyyy'],
		}"
		@update:modelValue="handleInput"
		:format="enableTimePicker ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'"
		:clearable="true"
		:locale="currentLanguageId != 1 ? 'en-US' : 'no'"
		:time-picker-inline="enableTimePicker"
		:enable-time-picker="enableTimePicker"
		:teleport="true"
		:placeholder="
			context.attrs.placeholder ??
			(enableTimePicker ? 'dd.mm.yyyy hh:mm' : 'dd.mm.yyyy')
		"
		:range="context.node.props.range"
		auto-apply
	>
		<template #clear-icon="{ clear }">
			<span></span>
		</template>
	</VueDatePicker>
</template>
<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { format, parse } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

const { currentLanguageId } = useAppLanguage();

// UTC
const internalValue = computed(() => {
	if (!props.context?._value) return null;

	// Handle range values (fromDate, toDate)
	if (
		typeof props.context._value === 'object' &&
		props.context._value.fromDate &&
		props.context._value.toDate
	) {
		return [
			fromZonedTime(
				parse(
					props.context._value.fromDate,
					props.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
					new Date(),
				),
				'UTC',
			),
			fromZonedTime(
				parse(
					props.context._value.toDate,
					props.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
					new Date(),
				),
				'UTC',
			),
		];
	}

	// Handle string value
	if (typeof props.context._value === 'string') {
		return fromZonedTime(
			parse(
				props.context._value,
				props.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
				new Date(),
			),
			'UTC',
		);
	}

	return props.context._value;
});

const props = defineProps<{ context: any }>();

const enableTimePicker = props.context.node?.props?.timePicker ?? false;

// Set initial value
if (props.context?._value) {
	// Handle range values (fromDate, toDate)
	if (
		typeof props.context._value === 'object' &&
		props.context._value.fromDate &&
		props.context._value.toDate
	) {
		// Range is already properly formatted, no need to reformat
		// Just ensure it's in the correct format
		const fromDate = format(
			new Date(props.context._value.fromDate),
			props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
		);
		const toDate = format(
			new Date(props.context._value.toDate),
			props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
		);

		props.context?.node.input({
			fromDate,
			toDate,
		});
	} else if (typeof props.context._value === 'string') {
		// Handle string value
		const date = new Date(props.context._value);
		const formattedDate = format(
			date,
			props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
		);

		props.context?.node.input(formattedDate);
	} else {
		// Handle Date object
		const formattedDate = format(
			props.context._value,
			props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
		);

		props.context?.node.input(formattedDate);
	}
} else if (props.context.node?.props?.todayInitial) {
	// Handle range with today initial
	if (props.context.node?.props?.range) {
		const today = new Date();
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);

		props.context?.node.input(
			{
				fromDate: format(
					today,
					props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
				),
				toDate: format(
					tomorrow,
					props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
				),
			},
			false,
		);
	} else {
		// Single date with today initial
		props.context?.node.input(
			format(
				new Date(),
				props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
			),
			false,
		);
	}
}

function handleInput(value: string | string[]) {
	if (!value) {
		props.context?.node.input(null);
		return;
	}

	if (Array.isArray(value)) {
		const formattedDates = value.map((date) =>
			format(
				date,
				props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
			),
		);

		props.context?.node.input({
			fromDate: formattedDates[0],
			toDate: formattedDates[1],
		});
	} else if (!enableTimePicker) {
		const formattedDate = format(
			value,
			props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
		);

		props.context?.node.input(formattedDate);
	} else {
		const date = toZonedTime(value, 'UTC');

		const formattedDate = format(
			date,
			props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
		);

		props.context?.node.input(formattedDate);
	}
}
</script>

<style>
@reference '~/assets/css/style.css';

.dp__menu {
	@apply border-slate-200 shadow-lg;
}

.dp__instance_calendar {
	@apply text-sm;
	font-family: 'figtree', sans-serif;
}

.dp__calendar_header_separator {
	@apply bg-slate-200;
}

.dp__input {
	@apply mr-5 h-[26px] !rounded-md border border-slate-200 !bg-white py-1 pl-2.5 text-xs text-slate-700 !shadow-none outline-hidden! hover:!border-slate-200 focus:border-slate-200 focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 sm:h-auto sm:py-1.5 sm:text-sm;

	font-family: 'figtree', sans-serif;

	&[readonly] {
		@apply text-slate-400 hover:cursor-not-allowed hover:border-inherit focus:border-inherit focus:bg-inherit focus:ring-inherit;
	}
}

.dp__input_icon,
.dp__instance_calendar,
.dp__calendar_item {
	@apply text-slate-500;
}

.dp__input_icon {
	@apply right-0 left-auto;
}

.formkit-form-inline .dp__input_icon {
	@apply right-15;
}

.dp__month_year_row {
	@apply m-1;
}

.dp__disabled,
.dp__disabled + div .dp__input_icon {
	@apply cursor-not-allowed fill-slate-400 text-slate-400;
}

.formkit-outer[data-type='datepicker'][data-invalid='true'] .dp__input {
	@apply ring-2 ring-red-700;
}
</style>
